import React, { useState, useEffect, useCallback } from "react";
import { useGlobalContext } from "./GlobalContext";
import {
  Container,
  Accordion,
  Placeholder
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ModalEquipe from "./ModalEquipe.js";
import {
  collection,
  onSnapshot,
  query,
} from "firebase/firestore";
import LoadingButton from "./LoadingButton.js";
function ManagementEquipes() {
  const {
    db,
    getLicencie,
    getMiniUserPresentation
  } = useGlobalContext();
  const [equipes, setEquipes] = useState([]);
  const [currentEquipe, setCurrentEquipe] = useState(null);
  const [modalEquipeShow, setModalEquipeShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const getCapitaines = useCallback(async (equipe) => {
    const retour = [];
    for (const encUid of equipe["capitaines"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  const getJoueurs = useCallback(async (equipe) => {
    const retour = [];
    for (const encUid of equipe["joueurs"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);


  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/équipes"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let retour = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const equipe = doc.data();
          equipe["capitaines"] = await getCapitaines(equipe);
          equipe["joueurs"] = await getJoueurs(equipe);;
          retour.push(equipe)
        })
      );
      retour = retour.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      setEquipes(retour);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [db, getCapitaines, getJoueurs]);

  const modifyEquipe = (equipe) => {
    setCurrentEquipe(equipe);
    setModalEquipeShow(true);
  };

  const ajoutEquipe = () => {
    setCurrentEquipe(null);
    setModalEquipeShow(true);
  };

  const onValid = () => {
    setModalEquipeShow(false);
  };

  const onCancel = () => {
    setModalEquipeShow(false);
  };

  return (
    <Container>
      <h3
        className="section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Equipes
        <LoadingButton className="btn btn-custom2" onClick={ajoutEquipe}>
          Ajouter une équipe
        </LoadingButton>
      </h3>
      <Container style={{ marginBottom: "20px" }}>
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
          {loading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
              <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            : equipes.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>Aucune équipe</span>
            ) : (
              equipes.map((equipe, index) => {
                const capitaines = equipe["capitaines"];
                const joueurs = equipe["joueurs"];
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1 1 auto" }}>
                            <b>
                              <span style={{ flex: "1 1 0" }}>{equipe.name}</span>
                            </b>
                            <span>{equipe.level}</span>
                          </div>

                          {(db.isMemberBureau || db.isAdmin) && (
                            <div
                              className="btn btn-custom1"
                              onClick={(e) => {
                                modifyEquipe(equipe);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                disabled={!db.isAdmin && !db.isMemberBureau}
                              />
                            </div>
                          )}
                        </div>
                        <div style={{
                          margin: "10px",
                          width: "95%"
                        }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                              backgroundColor: "rgb(230, 230, 230)",
                              padding: "10px",
                              borderRadius: "3px"
                            }}
                          >
                            <div>
                              Capitaines
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "0.8em",
                                  marginLeft: "10px",
                                }}
                              >
                                ({capitaines.length})
                              </span>
                            </div>
                          </div>
                          <Container
                            key={index}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexWrap: "wrap"
                            }}
                          >
                            {capitaines.length === 0 ? (
                              <span>Aucun capitaine</span>
                            ) : (
                              capitaines.map((capitaine, index) => {
                                return (
                                  getMiniUserPresentation(
                                    capitaine,
                                    "",
                                    index
                                  )
                                );
                              })
                            )}
                          </Container>

                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div style={{ width: "95%", margin: "10px", marginTop: "0px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "10px",
                            backgroundColor: "rgb(230, 230, 230)",
                            padding: "10px",
                            borderRadius: "3px"
                          }}
                        >
                          <div>
                            Joueurs
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "0.8em",
                                marginLeft: "10px",
                              }}
                            >
                              ({joueurs.length})
                            </span>
                          </div>
                        </div>
                        <Container
                          key={index}
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap"
                          }}
                        >
                          {joueurs.length === 0 ? (
                            <span>Aucun joueur</span>
                          ) : (
                            joueurs.map((joueur, index) => {
                              return (
                                getMiniUserPresentation(
                                  joueur,
                                  "",
                                  index
                                )
                              );
                            })
                          )}
                        </Container>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            )}
        </Accordion>
      </Container>

      <ModalEquipe
        show={modalEquipeShow}
        equipe={currentEquipe}
        onHide={() => setModalEquipeShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container>
  );
}

export default ManagementEquipes;

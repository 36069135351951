import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Form,
  ButtonGroup,
  Container,
  FloatingLabel
} from "react-bootstrap";
import LoadingButton from "./LoadingButton.js";
import { useGlobalContext } from "./GlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";

function ModalTraining({ show, training, onHide, onValid, onCancel, edit }) {
  const {
    db,
    getLicencie,
    getLicencies,
    Locations,
    handleShowConfirmation,
    handleCloseConfirmation,
    onShowModalSelection
  } = useGlobalContext();
  const [participants, setParticipants] = useState([]);
  const [encadrants, setEncadrants] = useState([]);
  const [lieu, setLieu] = useState(training ? training.lieu : "");
  const [description, setDescription] = useState(training ? training.description : "");
  const creneau = training ? training.creneau : null;


  const getEncadrants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["encadrants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  const getParticipants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["participants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);

  const handleUpdatetraining = async () => {
    try {
      await db.updateTraining(creneau.uid, training.uid, { lieu, description });
      onValid();
    } catch (error) {
      console.log("Error modif training", error);
    }
  };

  const ajoutParticipant = async (selectedParticipants) => {
    try {
      if (selectedParticipants.length !== 1)
        console.error("Plusieurs users sélectionné");
      const uid = selectedParticipants[0];
      await db.addParticipant(creneau.uid, training.uid, uid);
    } catch (error) {
      console.log("Error ajout participant", error);
    }
  }

  const handleAjoutParticipant = async () => {
    const licencies = await getLicencies();
    onShowModalSelection(
      ajoutParticipant,
      "Ajouter un participant",
      participants.map(u => u.uid),
      () => licencies
    );
  }

  const ajoutEncadrant = async (selectedEncadrant) => {
    try {
      if (selectedEncadrant.length !== 1)
        console.error("Plusieurs users sélectionné");
      const uid = selectedEncadrant[0];
      await db.addEncadrant(creneau.uid, training.uid, uid);
    } catch (error) {
      console.log("Error ajout encadrant", error);
    }
  }

  const handleAjoutEncadrant = async () => {
    const licencies = await getLicencies();
    onShowModalSelection(
      ajoutEncadrant,
      "Ajouter un encadrant",
      encadrants.map(u => u.uid),
      () => licencies
    );
  }

  const locations = Object.keys(Locations).map((key) => {
    return (
      <LoadingButton
        key={key}
        variant="secondary"
        className={lieu === Locations[key] ? "buttonChecked" : ""}
        onClick={() => setLieu(Locations[key])}
      >
        {Locations[key]}
      </LoadingButton>
    );
  });


  const onValidRemoveParticipant = async (training, userUid) => {
    await db.removeParticipant(training.creneau.uid, training.uid, userUid);
    handleCloseConfirmation();
  }

  const removeParticipant = async (training, userUid) => {
    const licencie = await getLicencie(userUid);
    handleShowConfirmation(
      "Retirer un joueur",
      `Voulez-vous retirer ${licencie.displayName} de cet entraînement ?`,
      "danger",
      "Retirer",
      "Annuler",
      null,
      () => onValidRemoveParticipant(training, userUid)
    );
  };

  const onValidRemoveEncadrant = async (training, userUid) => {
    await db.removeEncadrant(training.creneau.uid, training.uid, userUid);
    handleCloseConfirmation();
  }

  const removeEncadrant = async (training, userUid) => {
    const licencie = await getLicencie(userUid);
    handleShowConfirmation(
      "Retirer un encadrant",
      `Voulez-vous retirer ${licencie.displayName} de cet entraînement ?`,
      "danger",
      "Retirer",
      "Annuler",
      null,
      () => onValidRemoveEncadrant(training, userUid)
    );
  };

  useEffect(() => {

    let unsubscribeTraining = null;
    if (training) {
      const retour = [];
      const q = query(collection(db.db, "clubs/badlevier/créneaux/" + training.creneau.uid + "/entraînements"), where("uid", "==", training.uid), limit(1));
      unsubscribeTraining = onSnapshot(q, (querySnapshotTraining) => {
        querySnapshotTraining.forEach(async (doc) => {
          const training = doc.data();
          const trainingFindIndex = retour.findIndex(ent => ent.uid === training.uid);
          if (trainingFindIndex > -1) {
            retour[trainingFindIndex] = training;
          } else {
            retour.push(training);
          }
          setParticipants(await getParticipants(training));
          setEncadrants(await getEncadrants(training));
          setLieu(training.lieu);
          setDescription(training.description);
        });
      });
    } else {
      setParticipants([]);
      setEncadrants([]);
      setLieu("");
      setDescription("");
    }

    if (!show) {
      if (unsubscribeTraining) unsubscribeTraining();
    }
    return () => {
      if (unsubscribeTraining) unsubscribeTraining();
    };

  }, [show, training, db, creneau, getEncadrants, getParticipants])


  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {edit ? "Modification d'un entraînement" : "Détail entraînement"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 auto",
                margin: "0px 10px",
              }}
            >
              {edit ? (
                <ButtonGroup style={{ marginBottom: "10px" }}>
                  {locations}
                </ButtonGroup>)
                :
                training && creneau && training.lieu === creneau.lieu ? (
                  <label className="labelKeyValue" >
                    Lieu
                    <span>{training.lieu}</span>
                  </label>
                ) : (
                  <label className="labelKeyValue" style={{ width: "100%" }}>
                    Lieu
                    <div style={{ fontSize: "0.8em", display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-end" }}>
                      <strike> <span style={{ marginBottom: "5px" }}>{creneau && creneau.lieu}</span></strike>
                      <span style={{ backgroundColor: "yellow", padding: "3px" }}>{training && training.lieu}</span>
                    </div>
                  </label>
                )
              }

              <div
                style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}
              >
                <div
                  className="section"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <b>Participants</b>

                    < span
                      style={{
                        fontStyle: "italic",
                        fontSize: "0.8em",
                        marginLeft: "10px",
                      }}
                    >
                      ({participants.length})
                    </span>
                  </div>
                  {edit && (
                    <LoadingButton className="btn btn-custom2" onClickPromise={handleAjoutParticipant}>
                      Ajouter
                    </LoadingButton>)}
                </div>

                <Container>
                  {participants.length === 0 ? (
                    <span>Aucun participant</span>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {participants.map((participant, index) => {
                        return (
                          <span
                            key={index}
                            style={{ textWrap: "nowrap", margin: "0px 10px", border: "solid 1px green", borderRadius: "3px", padding: "2px 5px" }}
                          >
                            {participant.displayName}
                            <FontAwesomeIcon
                              icon={faCircleMinus}
                              style={{
                                fontSize: "1em",
                                marginLeft: "5px",
                                color: "red",
                                cursor: "pointer"
                              }}
                              onClick={() => removeParticipant(training, participant.uid)}
                            />
                          </span>
                        )
                      })}
                    </div>
                  )}
                </Container>

                <div
                  className="section"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px"
                  }}
                >
                  <div>
                    <b>Encadrants</b>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: "0.8em",
                        marginLeft: "10px",
                      }}
                    >
                      ({encadrants.length})
                    </span>
                  </div>
                  {edit && (
                    <LoadingButton className="btn btn-custom2" onClickPromise={handleAjoutEncadrant}>
                      Ajouter
                    </LoadingButton>)}
                </div>
                <Container>
                  {encadrants.length === 0 ? (
                    <span>Aucun encadrant</span>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {encadrants.map((encadrant, index) => {
                        return (
                          <span
                            key={index}
                            style={{ textWrap: "nowrap", margin: "0px 10px", border: "solid 1px green", borderRadius: "3px", padding: "2px 5px" }}
                          >
                            {encadrant.displayName}
                            <FontAwesomeIcon
                              icon={faCircleMinus}
                              style={{
                                fontSize: "1em",
                                marginLeft: "5px",
                                color: "red",
                                cursor: "pointer"
                              }}
                              onClick={() => removeEncadrant(training, encadrant.uid)}
                            />
                          </span>
                        )
                      })}
                    </div>
                  )}
                </Container>
              </div>
              <FloatingLabel controlId="floatingTextarea2" label="Description" style={{ marginTop: "10px" }}>
                <Form.Control
                  className="mb-3"
                  as="textarea"
                  placeholder="Description"
                  style={{ height: "100px" }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </FloatingLabel>
            </div>
          </div>
        </Form>
      </Modal.Body >
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {edit ?
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >

              <LoadingButton className="btn btn-custom1" onClick={onCancel}>
                Annuler
              </LoadingButton>
              <LoadingButton
                className="btn btn-custom2"
                onClickPromise={handleUpdatetraining}
              >
                {"Enregistrer"}
              </LoadingButton>
            </div>
            :
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <LoadingButton className="btn btn-custom1" onClick={onCancel}>
                Fermer
              </LoadingButton>
            </div>
          }

        </div>
      </Modal.Footer>
    </Modal >
  );
}

export default ModalTraining;

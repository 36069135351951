import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  FloatingLabel,
  Form,
  ButtonGroup,
  Container
} from "react-bootstrap";
import LoadingButton from "./LoadingButton.js";
import { useGlobalContext } from "./GlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";
function ModalCreneau({ show, creneau, onHide, onValid, onCancel }) {
  const {
    db,
    EventType,
    DaysOfWeek,
    Locations,
    handleShowConfirmation,
    handleCloseConfirmation,
    getLicencie,
    onShowModalSelection,
    getLicencies
  } = useGlobalContext();
  const [type, setType] = useState("");
  const [jour, setJour] = useState("");
  const [heureDebut, setHeureDebut] = useState("");
  const [heureFin, setHeureFin] = useState("");
  const [lieu, setLieu] = useState("");
  const [encadrants, setEncadrants] = useState([]);

  const getEncadrantsCreneau = useCallback(async (creneau) => {
    const retour = [];
    for (const encUid of creneau["encadrants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  useEffect(() => {
    let unsubscribeCreneau = null;
    if (show) {
      if (creneau) {
        const retour = [];
        const q = query(collection(db.db, "clubs/badlevier/créneaux"), where("uid", "==", creneau.uid), limit(1));
        unsubscribeCreneau = onSnapshot(q, async (querySnapshotCreneau) => {
          await Promise.all(
            querySnapshotCreneau.docs.map(async (doc) => {
              const creneau = doc.data();
              const creneauFindIndex = retour.findIndex(equ => equ.uid === creneau.uid);
              if (creneauFindIndex > -1) {
                retour[creneauFindIndex] = creneau;
              } else {
                retour.push(creneau);
              }
              setType(creneau.type || "");
              setJour(creneau.jour || "");
              setHeureDebut(creneau.heureDebut || "");
              setHeureFin(creneau.heureFin || "");
              setLieu(creneau.lieu || "");
              setEncadrants(await getEncadrantsCreneau(creneau));
            }));
        });
      } else {
        setType("");
        setJour("");
        setHeureDebut("20:00");
        setHeureFin("22:00");
        setLieu("");
        setEncadrants([]);
      }
    }
    return () => {
      if (unsubscribeCreneau) unsubscribeCreneau();
    };
  }, [db, show, creneau, getEncadrantsCreneau]);

  const onValidAjout = async () => {
    try {
      await db.addCreneau({ type, jour, heureDebut, heureFin, lieu });
      onValid();
    } catch (error) {
      console.log("Error create creneau", error);
    }
  };

  const handleUpdateCreneau = async () => {
    try {
      await db.updateCreneau(creneau.uid, {
        type,
        jour,
        heureDebut,
        heureFin,
        lieu,
      });
      onValid();
    } catch (error) {
      console.log("Error edit creneau", error);
    }
  };

  const handleValidDeleteCreneau = async () => {
    try {
      await db.removeCreneau(creneau.uid);
      onValid();
      handleCloseConfirmation();
    } catch (error) {
      console.log("Error delete creneau", error);
    }
  };

  const typeCreneaux = [
    EventType.ADULTE_TRAINING,
    EventType.YOUNG_TRAINING,
  ].map((typeCreneau, index) => {
    return (
      <LoadingButton
        key={index}
        variant="secondary"
        className={type === typeCreneau ? "buttonChecked" : ""}
        onClick={() => setType(typeCreneau)}
      >
        {typeCreneau}
      </LoadingButton>
    );
  });

  const jours = DaysOfWeek.map((dayName, index) => {
    return (
      <LoadingButton
        key={index}
        variant="secondary"
        className={jour === index ? "buttonChecked" : ""}
        onClick={() => setJour(index)}
      >
        {dayName}
      </LoadingButton>
    );
  });

  const locations = Object.keys(Locations).map((key) => {
    return (
      <LoadingButton
        key={key}
        variant="secondary"
        className={lieu === Locations[key] ? "buttonChecked" : ""}
        onClick={() => setLieu(Locations[key])}
      >
        {Locations[key]}
      </LoadingButton>
    );
  });

  const handleAjoutEncadrant = async () => {
    const licencies = await getLicencies();
    onShowModalSelection(
      ajoutEncadrant,
      "Ajouter un encadrant",
      encadrants.map(u => u.uid),
      () => licencies
    );
  }

  const ajoutEncadrant = async (selectedEncadrant) => {
    try {
      if (selectedEncadrant.length !== 1)
        console.error("Plusieurs users sélectionné");
      const uid = selectedEncadrant[0];
      await db.addEncadrantCreneau(creneau.uid, uid);
    } catch (error) {
      console.log("Error ajout encadrant", error);
    }
  }

  const onValidRemoveEncadrant = async (creneau, userUid) => {
    await db.removeEncadrantCreneau(creneau.uid, userUid);
    handleCloseConfirmation();
  }

  const removeEncadrant = async (creneau, userUid) => {
    const licencie = await getLicencie(userUid);
    handleShowConfirmation(
      "Retirer un encadrant",
      `Voulez-vous retirer ${licencie.displayName} de ce créneau ?`,
      "danger",
      "Retirer",
      "Annuler",
      null,
      () => onValidRemoveEncadrant(creneau, userUid)
    );
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {creneau ? "Modification d'un créneau" : "Ajout d'un créneau"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ButtonGroup vertical>{jours}</ButtonGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                  margin: "0px 10px",
                }}
              >
                <ButtonGroup style={{ marginBottom: "10px" }}>
                  {typeCreneaux}
                </ButtonGroup>
                <FloatingLabel
                  label="Heure de début"
                  className="mb-3"
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    type="time"
                    style={{ width: "100%" }}
                    value={heureDebut}
                    step="300"
                    required
                    onChange={(e) => setHeureDebut(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label="Heure de fin"
                  className="mb-3"
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    type="time"
                    step="300"
                    style={{ width: "100%" }}
                    value={heureFin}
                    required
                    onChange={(e) => setHeureFin(e.target.value)}
                  />
                </FloatingLabel>
                <ButtonGroup style={{ marginBottom: "10px" }}>
                  {locations}
                </ButtonGroup>
              </div>
            </div>
            <div
              className="section"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px"
              }}
            >
              <div>
                <b>Encadrants</b>
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "0.8em",
                    marginLeft: "10px",
                  }}
                >
                  ({encadrants.length})
                </span>
              </div>
              <LoadingButton className="btn btn-custom2" onClickPromise={handleAjoutEncadrant}>
                Ajouter
              </LoadingButton>
            </div>
            <Container>
              {encadrants.length === 0 ? (
                <span>Aucun encadrant</span>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {encadrants.map((encadrant, index) => {
                    return (
                      <span
                        key={index}
                        style={{ textWrap: "nowrap", margin: "0px 10px", border: "solid 1px green", borderRadius: "3px", padding: "2px 5px" }}
                      >
                        {encadrant.displayName}
                        <FontAwesomeIcon
                          icon={faCircleMinus}
                          style={{
                            fontSize: "1em",
                            marginLeft: "5px",
                            color: "red",
                            cursor: "pointer"
                          }}
                          onClick={() => removeEncadrant(creneau, encadrant.uid)}
                        />
                      </span>
                    )
                  })}
                </div>
              )}
            </Container>

          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {creneau ? (
            <LoadingButton
              className="btn btn-custom5"
              onClick={() => {
                handleShowConfirmation(
                  "",
                  "Etes vous sur de vouloir retirer ce créneau ?",
                  "danger",
                  "Retirer un créneau",
                  "Annuler",
                  handleValidDeleteCreneau
                );
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
              />
              Retirer
            </LoadingButton>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <LoadingButton className="btn btn-custom1" onClick={onCancel}>
              Annuler
            </LoadingButton>
            <LoadingButton
              className="btn btn-custom2"
              onClickPromise={creneau ? handleUpdateCreneau : onValidAjout}
              disabled={
                jour === null ||
                type === null ||
                heureDebut === "" ||
                heureFin === ""
              }
            >
              {creneau ? "Enregistrer" : "Ajouter"}
            </LoadingButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCreneau;

import React, { useState, useEffect, useMemo } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Accordion, Placeholder, FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalArticle from "./ModalArticle.js";
import {
  onSnapshot,
  query,
  collection,
} from "firebase/firestore";
import LoadingButton from "./LoadingButton.js";
function ManagementBoutique() {
  const {
    db,
    user,
    getPhotoArticle,
    handleShowConfirmation,
    handleCloseConfirmation,
    getDateTraining,
    TypeMouvement,
    getLicencie,
    TypeMotifStock,
    TailleHomme,
    TailleFemme,
    TailleEnfant,
    StatusMouvement
  } = useGlobalContext();
  const [articles, setArticles] = useState([]);
  const [commandeEncours, setCommandeEncours] = useState([]);
  const [currentArticleEdit, setCurrentArticleEdit] = useState(null);
  const [modalArticleShow, setModalArticleShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nbWeekBefore, setNbWeekBefore] = useState(52);

  const initQteTailleHomme = useMemo(() => {
    const retour = {};
    TailleHomme.map(t => retour["_" + t] = 0);
    return retour;
  }, [TailleHomme]);
  const initQteTailleFemme = useMemo(() => {
    const retour = {};
    TailleFemme.map(t => retour["_" + t] = 0);
    return retour;
  }, [TailleFemme]);
  const initQteTailleEnfant = useMemo(() => {
    const retour = {};
    TailleEnfant.map(t => retour["_" + t] = 0);
    return retour;
  }, [TailleEnfant]);

  const modifyArticle = (article) => {
    setCurrentArticleEdit(article);
    setModalArticleShow(true);
  };
  const ajoutArticle = () => {
    setCurrentArticleEdit(null);
    setModalArticleShow(true);
  };

  const onValid = () => {
    setModalArticleShow(false);
  };

  const onCancel = () => {
    setModalArticleShow(false);
  };

  const onValidPurgeMouvements = async () => {
    //TODO
    handleCloseConfirmation();
  }

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/articles"));
    const unsubscribeCommandes = [];
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let retour = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const article = doc.data();
          for (let i = 0; i < article["mouvementStock"].length; i++) {
            article["mouvementStock"][i]["modifiedBy"] = await getLicencie(article["mouvementStock"][i]["modifiedBy"]);
            article["mouvementStock"][i]["licencie"] = await getLicencie(article["mouvementStock"][i]["licencie"]);
          }
          const articleFindIndex = retour.findIndex(art => art?.uid === article?.uid);
          if (articleFindIndex > -1) {
            retour[articleFindIndex] = article;
          } else {
            retour.push(article);
          }
        })
      );

      retour = retour.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
      )

      //récupération des mouvements regroupé par articles
      const q1 = query(collection(db.db, "clubs/badlevier/mouvements"));
      const unsubscribeCommande = onSnapshot(q1, async (querySnapshot2) => {
        await Promise.all(
          querySnapshot2.docs.map(async (doc) => {
            const mouvement = doc.data();
            const licencie = await getLicencie(mouvement.licencie);
            if (mouvement.panier) {
              for (let i = 0; i < mouvement.panier.length; i++) {
                const article = mouvement.panier[i];
                const articleFindIndex = retour.findIndex(art => art?.uid === article?.uid);
                if (articleFindIndex > -1) {
                  if (retour[articleFindIndex]["withTaille"]) {
                    if (retour[articleFindIndex]["commandes"] === undefined) {
                      retour[articleFindIndex]["commandes"] = [];
                    }
                    var eltFind = retour[articleFindIndex]["commandes"].find(elt => elt.tailleGenre === article["tailleGenre"]);
                    if (eltFind === undefined) {
                      retour[articleFindIndex]["commandes"].push({ "tailleGenre": article["tailleGenre"], "tailles": [] });
                      eltFind = retour[articleFindIndex]["commandes"].find(elt => elt.tailleGenre === article["tailleGenre"])
                    }
                    var eltFind2 = eltFind["tailles"].find(elt => elt["taille"] === article["taille"]);
                    if (eltFind2 === undefined) {
                      eltFind["tailles"].push({
                        "taille": article["taille"],
                        "nbCommande": 0,
                        "commandePour": (licencie ? (licencie.displayName + (mouvement["status"] === StatusMouvement.ATTENTE_DISTRIBUTION ? "💶" : "")) : "Inconnu"),
                        "flocageAvant": article["flocageAvant"] !== undefined ? article["flocageAvant"] : ""
                      });
                      eltFind2 = eltFind["tailles"].find(elt => elt.taille === article["taille"])
                    } else {
                      eltFind2["commandePour"] += " ; " + (licencie ? (licencie.displayName + (mouvement["status"] === StatusMouvement.ATTENTE_DISTRIBUTION ? "💶" : "")) : "Inconnu");
                    }
                    eltFind2["nbCommande"] += article.nb;
                  } else {
                    if (retour[articleFindIndex]["nbCommande"] === undefined) {
                      retour[articleFindIndex]["nbCommande"] = 0;
                    }
                    retour[articleFindIndex]["nbCommande"] += article.nb;
                    const licencie = await getLicencie(mouvement.licencie);
                    if (retour[articleFindIndex]["commandePour"] === undefined) {
                      retour[articleFindIndex]["commandePour"] = (licencie ? (licencie.displayName + (mouvement["status"] === StatusMouvement.ATTENTE_DISTRIBUTION ? "💶" : "")) : "Inconnu");
                    } else {
                      retour[articleFindIndex]["commandePour"] += " ; " + (licencie ? (licencie.displayName + (mouvement["status"] === StatusMouvement.ATTENTE_DISTRIBUTION ? "💶" : "")) : "Inconnu");
                    }
                  }
                }
              }
            }
          }));
        const c = retour.filter(art => art["nbCommande"] !== undefined || (art["withTaille"] !== undefined && art["commandes"] !== undefined));
        setCommandeEncours(c);
        setLoading(false);
      });

      unsubscribeCommandes.push(unsubscribeCommande);
      setArticles(retour);
    });

    return () => {
      unsubscribeCommandes.forEach(un => un());
      unsubscribe();
    };
  }, [db, getLicencie, StatusMouvement]);

  return (
    <Container>
      <div
        className="section"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <LoadingButton className="btn btn-custom2" onClick={ajoutArticle}>
            Ajouter un article
          </LoadingButton>
          <span
            style={{
              fontStyle: "italic",
              fontSize: "0.8em",
              marginLeft: "10px",
            }}
          >
            <b>{articles.length} article(s)</b>
          </span>
        </div>

      </div>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : articles.length !== 0 && (
            <>
              <Accordion
                defaultActiveKey="0"
                style={{ maxWidth: "600px", width: "100%" }}
              >
                {articles.map((article, index) => {
                  const mouvementStock = article.mouvementStock;
                  const withTaille = article.withTaille;
                  const qteTailleHommeObj = withTaille && article.qteTailleHomme ? JSON.parse(article.qteTailleHomme) : initQteTailleHomme;
                  const qteTailleFemmeObj = withTaille && article.qteTailleFemme ? JSON.parse(article.qteTailleFemme) : initQteTailleFemme;
                  const qteTailleEnfantObj = withTaille && article.qteTailleEnfant ? JSON.parse(article.qteTailleEnfant) : initQteTailleEnfant;
                  const articleWithCommande = commandeEncours.find(art => art.uid === article.uid);
                  return (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      style={{
                      }}
                    >
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                flex: "1 1 auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                  opacity: article.visible ? 1 : 0.5
                                }}
                              >
                                <span style={{ marginBottom: "5px" }}>
                                  <b>{article.title} </b>
                                </span>


                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  fontWeight: "bold"
                                }}
                              >
                                <span style={{ minWidth: "70px", textAlign: "right" }}>
                                  {article.prix} €
                                </span>
                              </div>

                            </div>

                            <div
                              className="btn btn-custom1"
                              onClick={(e) => {
                                modifyArticle(article);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                              />
                            </div>

                          </div>
                          <div style={{ width: "100%" }}>
                            {!withTaille &&
                              <>
                                <label>
                                  Stock
                                  <span>{article.qte}</span>
                                </label>
                                {articleWithCommande !== undefined && articleWithCommande["nbCommande"] !== undefined &&
                                  <label>
                                    Commandes en cours
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      <span>{articleWithCommande["nbCommande"]}</span>
                                      <div style={{ fontStyle: "italic" }}>({articleWithCommande["commandePour"]})</div>
                                    </div>
                                  </label>
                                }
                              </>
                            }
                            {/*article.stockDiff === null ?
                              <label>
                                Il s'agit du stock initial à la création de l'article
                              </label>
                              : article.stockDiff === 0 ?
                                <div style={{ marginLeft: "20px" }}>
                                  <label>
                                    Vérifié par
                                    <span>{licencieModifStock.displayName}</span>
                                  </label>
                                  <label>
                                    Vérifié le
                                    <span>{getDateTraining(article.stockLastModified)}</span>
                                  </label>
                                </div>
                                : article.stockDiff > 0 ?
                                  <div style={{ marginLeft: "20px" }}>
                                    <label>
                                      Modifié par
                                      <span>{licencieModifStock.displayName}</span>
                                    </label>
                                    <label>
                                      Modifié le
                                      <span>{getDateTraining(article.stockLastModified)}</span>
                                    </label>
                                    <label>
                                      Différence
                                      <span>{article.stockDiff} de plus</span>
                                    </label>
                                  </div>
                                  :
                                  <div style={{ marginLeft: "20px" }}>
                                    <label>
                                      Modifié par
                                      <span>{licencieModifStock.displayName}</span>
                                    </label>
                                    <label>
                                      Modifié le
                                      <span>{getDateTraining(article.stockLastModified)}</span>
                                    </label>
                                    <label>
                                      Diff
                                      <span>{article.stockDiff * -1} de moins</span>
                                    </label>
                                  </div>
                            */}
                          </div>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {withTaille &&
                          <>
                            <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-around" }}>
                              {[[TailleHomme, "Homme", qteTailleHommeObj],
                              [TailleFemme, "Femme", qteTailleFemmeObj],
                              [TailleEnfant, "Enfant", qteTailleEnfantObj]].map((eltType, index) => {
                                return (<div key={index} style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-around" }}>
                                  <span style={{}}>Stock {eltType[1]}</span>
                                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", width: "100%" }}>
                                    {eltType[0].map((currentType, index) => {
                                      return (
                                        <label key={index} style={{ marginRight: "5px" }} >
                                          <span style={{
                                            backgroundColor: "white", padding: "4px"
                                          }}>{currentType}</span>
                                          <span>{eltType[2]["_" + currentType]}</span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                  {articleWithCommande !== undefined && articleWithCommande["commandes"] !== undefined && articleWithCommande["commandes"].filter(elt => elt.tailleGenre === eltType[1]).length > 0 &&
                                    <div style={{ backgroundColor: "rgb(226, 240, 226)", padding: "0px 5px" }}>
                                      <span style={{}}>Commande {eltType[1]}</span>
                                      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", width: "100%" }}>
                                        {articleWithCommande["commandes"].filter(elt => elt.tailleGenre === eltType[1]).map((genre) => {
                                          return genre["tailles"].map((elt, index) => {
                                            return (
                                              <label key={index} >
                                                <span style={{
                                                  backgroundColor: "white", padding: "0px 5px"
                                                }}>{elt["taille"]}</span>
                                                <span>{elt["nbCommande"]}</span>
                                                <span style={{ fontStyle: "italic" }}>({elt["commandePour"]})</span>
                                              </label>
                                            );
                                          })
                                        })}
                                      </div>
                                    </div>
                                  }

                                </div>)
                              })}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              {article.photoUrl &&
                                getPhotoArticle(article.photoUrl, article.title, {
                                  maxHeight: "300px",
                                  maxWidth: "300px",
                                  height: "300px",
                                  width: "300px",
                                  borderRadius: "5px",
                                })
                              }
                              <span style={{ marginLeft: "10px" }}>{article.description}</span>
                            </div>
                          </>
                        }
                        {mouvementStock && !withTaille && (
                          <div style={{ marginTop: "10px", maxHeight: "300px", overflow: "auto" }}>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px 10px",
                              width: "100%",
                              backgroundColor: "rgb(198 221 198)",
                              boxShadow: "0px 3px 8px 0px #e3e3e3",
                              position: "sticky",
                              top: "0px",
                              borderRadius: "3px"
                            }}><b>Historique</b> <span>Stock : <b>{article.qte}</b></span></div>

                            {mouvementStock &&
                              mouvementStock.length === 0 ?
                              <span style={{
                                padding: "5px 20px",
                              }}>Aucun mouvement</span>

                              : mouvementStock.map((mouv, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", flexDirection: "column", fontSize: "0.8em", width: "100%" }}>
                                        <span style={{ fontWeight: "bold", minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                          {mouv["motif"]}
                                        </span>

                                        {mouv["modifiedBy"] &&
                                          <label className="labelKeyValue" style={{ fontSize: "0.8em" }} >
                                            <div>
                                              {mouv["modifiedBy"] &&
                                                <span> {(user && mouv["modifiedBy"].uid === user?.uid) ? "Vous" : mouv["modifiedBy"].displayName + " " + mouv["modifiedBy"].lastName}</span>
                                              }
                                              {mouv["date"] &&
                                                <>
                                                  <span>|</span>
                                                  <span>{getDateTraining(mouv["date"])}</span>
                                                </>
                                              }
                                            </div>
                                          </label>
                                        }
                                        {mouv["licencie"] &&
                                          <label className="labelKeyValue" style={{ fontSize: "0.8em" }} >
                                            <div>
                                              {mouv["licencie"] &&
                                                <span> Vendu à {(user && mouv["licencie"].uid === user?.uid) ? "Vous" : (mouv["licencie"].displayName + " " + mouv["licencie"].lastName)}</span>
                                              }
                                              {mouv["date"] &&
                                                <>
                                                  <span>|</span>
                                                  <span>{getDateTraining(mouv["date"])}</span>
                                                </>
                                              }
                                            </div>
                                          </label>
                                        }
                                      </div>
                                      <span style={{ color: mouv["typeMouvement"] === TypeMouvement.DEPOT ? "green" : "darkred", fontWeight: "bold", minWidth: "70px", textAlign: "right" }}>
                                        {(mouv["motif"] === TypeMotifStock.VERIFICATION ? "" : (mouv["typeMouvement"] === TypeMouvement.DEPOT ? "+ " : " ")) + mouv["qte"]}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          )
        }

      </Container >

      {<Container>
        <h3
          className="section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Récap commandes en cours
        </h3>
        <div
          style={{
            width: "100%",
          }}
        >
          {loading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
              <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            : commandeEncours.length === 0 ? (
              <label style={{ fontStyle: "italic", marginBottom: "10px", marginLeft: "10px" }}>Pas de commande en cours</label>
            ) : (
              <>
                {articles.map((article, index) => {
                  const withTaille = article.withTaille;
                  const qteTailleHommeObj = withTaille && article.qteTailleHomme ? JSON.parse(article.qteTailleHomme) : initQteTailleHomme;
                  const qteTailleFemmeObj = withTaille && article.qteTailleFemme ? JSON.parse(article.qteTailleFemme) : initQteTailleFemme;
                  const qteTailleEnfantObj = withTaille && article.qteTailleEnfant ? JSON.parse(article.qteTailleEnfant) : initQteTailleEnfant;
                  const articleWithCommande = commandeEncours.find(art => art.uid === article.uid);
                  return (<div key={index}>
                    {articleWithCommande !== undefined && articleWithCommande["nbCommande"] !== undefined &&
                      <div style={{ display: "flex", borderBottom: "1px lightgray solid", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "0px 10px" }}>
                        <span style={{ marginRight: "5px" }}>{articleWithCommande.title}</span>
                        <div style={{ display: "flex", }}>
                          <span style={{ fontWeight: "bold", marginRight: "5px" }}>{articleWithCommande["nbCommande"]}</span>
                          <div style={{ fontStyle: "italic" }}>({articleWithCommande["commandePour"]})</div>
                        </div>
                      </div>
                    }
                    {[[TailleHomme, "Homme", qteTailleHommeObj],
                    [TailleFemme, "Femme", qteTailleFemmeObj],
                    [TailleEnfant, "Enfant", qteTailleEnfantObj]].map((eltType, index) => {
                      return (<div key={index} style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-around" }}>
                        {articleWithCommande !== undefined && articleWithCommande["commandes"] !== undefined && articleWithCommande["commandes"].filter(elt => elt.tailleGenre === eltType[1]).length > 0 &&
                          <div style={{ display: "flex", borderBottom: "1px lightgray solid", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "0px 10px" }}>
                            <span style={{}}>{articleWithCommande.title} - {eltType[1]}</span>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap" }}>
                              {articleWithCommande["commandes"].filter(elt => elt.tailleGenre === eltType[1]).map((genre, index) => {
                                return genre["tailles"].map((elt, index) => {
                                  return (
                                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                      <label key={index} >
                                        <span style={{
                                          backgroundColor: "white", padding: "0px 5px", marginRight: "5px"
                                        }}>{elt["taille"]}</span>
                                        <span style={{ fontWeight: "bold", marginRight: "5px" }}>{elt["nbCommande"]} </span>
                                        <span style={{ fontStyle: "italic" }}>({elt["commandePour"]}
                                          {elt["flocageAvant"] !== "" ? (
                                            <span >
                                              <span> - Flocage avant : </span>
                                              <span style={{ backgroundColor: "yellow", padding: "0px 2px" }}>{elt["flocageAvant"]}</span>
                                            </span>) :
                                            ""})</span>
                                      </label>
                                      {elt["nbCommande"] - eltType[2]["_" + elt["taille"]] > 0 &&
                                        <span style={{ color: "#a6540f", fontWeight: "bold" }}>{elt["nbCommande"] - eltType[2]["_" + elt["taille"]]} taille {elt["taille"]} à commander</span>
                                      }
                                    </div>
                                  );
                                })
                              })}
                            </div>
                          </div>
                        }

                      </div>)
                    })}
                  </div>);
                })}
              </>
            )}
        </div>
      </Container>
      }
      {
        db.isAdmin ?
          <Container>
            <h3
              className="section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Purge
            </h3>
            <div
              style={{
                width: "100%",
              }}
            >
              <Form
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <FloatingLabel
                  label="Avant (nb semaines)"
                  style={{ flex: "1 1 auto", width: "100%" }}
                >
                  <Form.Control
                    type="number"
                    step="1"
                    min="52"
                    max="104"
                    style={{ width: "100%" }}
                    value={nbWeekBefore}
                    required
                    onChange={(e) => {
                      setNbWeekBefore(e.target.value)
                    }}
                  />
                </FloatingLabel>
                <LoadingButton
                  className="btn btn-custom5"
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleShowConfirmation(
                      "Purger les anciens mouvements",
                      "Voulez-vous supprimer les anciens mouvements ? Le stock restera inchangé.",
                      "danger",
                      "Supprimer",
                      "Annuler",
                      null,
                      onValidPurgeMouvements
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      marginRight: "5px"
                    }}
                  />
                  Retirer les anciens mouvements
                </LoadingButton>
              </Form>
            </div>
          </Container> : null
      }

      <ModalArticle
        show={modalArticleShow}
        currentArticleEdit={currentArticleEdit}
        onHide={() => setModalArticleShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container >
  );
}

export default ManagementBoutique;

import React, { useState } from "react";
import { useGlobalContext } from "./GlobalContext.js";
import { FloatingLabel, Form, ButtonGroup } from "react-bootstrap";
import LoadingButton from "./LoadingButton.js";

function MotifStock({ ecart, motif, setMotifStock }) {
  const {
    TypeMotifStock,
  } = useGlobalContext();

  const [typeMotif, setTypeMotif] = useState(motif);
  const [description, setDescription] = useState("");

  let typeMotifType = [];
  if (ecart > 0) {
    typeMotifType.push(TypeMotifStock.ACHAT);
    //typeMotifType.push(TypeMotifStock.RETOUR_COMMANDE);
    typeMotifType.push(TypeMotifStock.ERREUR);
    typeMotifType.push(TypeMotifStock.AUTRE);
  } else {
    typeMotifType.push(TypeMotifStock.INTERCLUB);
    //typeMotifType.push(TypeMotifStock.COMMANDE);
    typeMotifType.push(TypeMotifStock.ERREUR);
    typeMotifType.push(TypeMotifStock.AUTRE);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
      <ButtonGroup style={{ marginBottom: "10px", flex: "1 1 auto" }}>
        {typeMotifType.map((currentType, index) => {
          return (

            <LoadingButton
              key={index}
              //style={{ height: "40px" }}
              variant="secondary"
              className={currentType === typeMotif ? "buttonChecked" : ""}
              onClick={() => {
                setTypeMotif(currentType);
                setMotifStock(currentType === TypeMotifStock.AUTRE ? description : currentType);
              }}
            >
              {currentType}
            </LoadingButton >
          );
        })}
      </ButtonGroup>
      {typeMotif === TypeMotifStock.AUTRE &&
        <FloatingLabel controlId="floatingTextarea2" label="Motif du changement de stock" style={{ flex: "1 1 auto", width: "100%" }}>
          <Form.Control
            className="mb-3"
            as="textarea"
            placeholder="Donner la raison du changement de stock"
            style={{ height: "30px" }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setMotifStock(e.target.value);
            }}
          />
        </FloatingLabel>
      }
    </div>
  );
}

export default MotifStock;

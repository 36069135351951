import React from "react";
import LoadingButton from "./LoadingButton";

function MembreStructurel({
  header,
  onShowModalSelection,
  currentMembre,
  rights,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        width: "100%",
        backgroundColor: "white",
        marginBottom: "10px",
        borderRadius: "3px",
        justifyContent: "space-between",
      }}
    >
      <span style={{ marginRight: "20px", width: "150px" }}>{header}</span>
      {currentMembre ? (
        <span
          style={{
            fontWeight: "bold",
            width: "100px",
            textAlign: "center",
          }}
        >
          {currentMembre.displayName}
        </span>
      ) : (
        <span>-</span>
      )}
      <LoadingButton
        className="btn btn-custom2"
        onClick={() => onShowModalSelection()}
        disabled={!rights}
      >
        Changer
      </LoadingButton>
    </div>
  );
}

export default MembreStructurel;

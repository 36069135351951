import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import {
  Container,
  Accordion,
  Form,
  FloatingLabel,
  Placeholder
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ModalCreneau from "./ModalCreneau.js";
import { Timestamp } from "firebase/firestore";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDocs
} from "firebase/firestore";
import LoadingButton from "./LoadingButton.js";
function ManagementCreneaux() {
  const {
    db,
    DaysOfWeek,
    handleCloseConfirmation,
    handleShowConfirmation,
  } = useGlobalContext();
  const [creneaux, setCreneaux] = useState([]);
  const [currentCreneau, setCurrentCreneau] = useState(null);
  const [modalCreneauShow, setModalCreneauShow] = useState(false);
  const [nbWeekBefore, setNbWeekBefore] = useState(null);
  const [nbWeekAfter, setNbWeekAfter] = useState(null);
  const [isAnalyzed, setIsAnalyzed] = useState(false);
  const [trainingsToGenerate, setTrainingsToGenerate] = useState(null);
  const [trainingsToDelete, setTrainingsToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  const modifyCreneau = (creneau) => {
    setCurrentCreneau(creneau);
    setModalCreneauShow(true);
  };
  const ajoutCreneau = () => {
    setCurrentCreneau(null);
    setModalCreneauShow(true);
  };

  const onValid = () => {
    setModalCreneauShow(false);
  };

  const onCancel = () => {
    setModalCreneauShow(false);
  };


  /*const [generationDebut, setGenerationDebut] = useState(
    new Date().toISOString().split("T")[0]
  );*/
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + 1);
  /*const [generationFin, setGenerationFin] = useState(
    currentDate.toISOString().split("T")[0]
  );*/


  const getGenerateTrainings = () => {
    const trainingsToGenerateByCreneau = [];
    let generationDebut = new Date();
    generationDebut.setDate(generationDebut.getDate() - nbWeekBefore * 7);
    let generationFin = new Date();
    generationFin.setDate(generationFin.getDate() + nbWeekAfter * 7);
    creneaux.forEach((creneau) => {
      trainingsToGenerateByCreneau.push({
        creneau,
        trainingsToGenerate: getWeeklyRecurrenceDatesWithTime(
          generationDebut,
          generationFin,
          creneau["jour"],
          creneau
        ).map((date) => {
          return {
            creneauUid: creneau["uid"],
            date: date,
            lieu: creneau["lieu"],
            participants: [],
            encadrants: [],
            description: "",
            canceled: false,
          };
        }),
      });
    });
    return trainingsToGenerateByCreneau;
  };

  const getDeleteTrainings = () => {
    let generationDebut = new Date();
    generationDebut.setDate(generationDebut.getDate() - nbWeekBefore * 7);
    let generationFin = new Date();
    generationFin.setDate(generationFin.getDate() + nbWeekAfter * 7);
    const retour = [];
    for (let i = 0; i < creneaux.length; i++) {
      const creneau = creneaux[i];
      const ts = creneau["entraînements"];
      for (let j = 0; j < ts.length; j++) {
        const training = ts[j];
        if (training.date.toDate() < generationDebut || training.date.toDate() > generationFin) {
          retour.push({ creneauUid: creneau.uid, trainingUid: training.uid })
        }
      }
    }
    return retour;
  }

  const analyze = async () => {
    await Promise.all(
      creneaux.map(async (creneau) => {
        creneau["entraînements"] = [];
        const q1 = query(collection(db.db, "clubs/badlevier/créneaux/" + creneau.uid + "/entraînements"));
        const snap = await getDocs(q1);
        snap.docs.map((async (doc) => {
          const training = doc.data();
          creneau["entraînements"].push(training);
        }));
      }));

    setTrainingsToGenerate(getGenerateTrainings());
    setTrainingsToDelete(getDeleteTrainings());
    setIsAnalyzed(true);
  }

  useEffect(() => {
    //const unsubscribeTrainings = [];
    const q = query(collection(db.db, "clubs/badlevier/créneaux"));

    const unsubscribeCreneaux = onSnapshot(q, async (querySnapshotCreneau) => {
      const retour = [];
      await Promise.all(
        querySnapshotCreneau.docs.map(async (doc) => {
          const creneau = doc.data();
          //creneau["entraînements"] = [];
          /*const q1 = query(collection(db.db, "clubs/badlevier/créneaux/" + creneau.uid + "/entraînements"), limit(1));
          const unsubscribeChild = onSnapshot(q1, async (querySnapshotEntrainement) => {
            const trainings = [];
            await Promise.all(
              querySnapshotEntrainement.docs.map((async (doc) => {
                const training = doc.data();
                training["creneau"] = creneau;
                training["encadrants"] = await getEncadrants(training);
                training["participants"] = await getParticipants(training);
                const creneauFindIndex = trainings.findIndex(entrainement => entrainement.uid === training.uid);
                if (creneauFindIndex > -1) {
                  trainings[creneauFindIndex] = training;
                } else {
                  trainings.push(training);
                }
              })));
            creneau["entraînements"] = trainings;
          });*/
          retour.push(creneau);
          //unsubscribeTrainings.push(unsubscribeChild);
        }));
      setCreneaux([...retour]);
      setLoading(false);
    });


    const q2 = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeWeek = onSnapshot(q2, (doc) => {
      const badlevier = doc.data();
      setNbWeekBefore(badlevier["nbWeekBefore"]);
      setNbWeekAfter(badlevier["nbWeekAfter"]);
    });

    return () => {
      unsubscribeCreneaux();
      unsubscribeWeek();
      //unsubscribeTrainings.forEach(un => un());
    };
  }, [db]);

  const onValidGenerateTraining = async () => {
    for (let i = 0; i < trainingsToGenerate.length; i++) {
      const obj = trainingsToGenerate[i];
      const creneau = obj["creneau"];
      const subTrainingsToGenerate = obj["trainingsToGenerate"];
      for (let j = 0; j < subTrainingsToGenerate.length; j++) {
        await db.addTraining(creneau.uid, subTrainingsToGenerate[j]);
      }
    }
    setIsAnalyzed(false);
    handleCloseConfirmation();
  };

  const onValidDeleteTraining = async () => {
    try {
      for (let i = 0; i < trainingsToDelete.length; i++) {
        await db.removeTraining(trainingsToDelete[i].creneauUid, trainingsToDelete[i].trainingUid)
      }
      setIsAnalyzed(false);
      handleCloseConfirmation();
    } catch (error) {
      console.error("Problème pour supprimer les entrainements");
      return false;
    }
  };

  function getWeeklyRecurrenceDatesWithTime(
    startDate,
    endDate,
    dayOfWeek,
    creneau
  ) {
    const recurrenceDates = [];
    let currentDate = new Date(startDate);
    const heure = creneau.heureDebut.split(":")[0];
    const min = creneau.heureDebut.split(":")[0];
    currentDate.setHours(heure, min, 0, 0);
    // Fonction pour ajouter un nombre de jours à une date
    const addDays = (date, days) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + days);
      return newDate;
    };
    const datesEgales = (date1, date2) => {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    };

    // Conversion du jour de la semaine en numéro (0 pour dimanche, 1 pour lundi, ..., 6 pour samedi)
    const targetDay = dayOfWeek % 7;

    let firstDay = currentDate;
    while (firstDay.getUTCDay() !== targetDay) {
      firstDay = addDays(firstDay, 1);
    }
    currentDate = firstDay;

    let endDate2 = new Date(endDate);
    while (currentDate <= endDate2) {
      const date = new Date(currentDate);
      if (date.getUTCDay() === targetDay) {
        if (
          creneau["entraînements"].filter((o) =>
            datesEgales(o.date.toDate(), date)
          ).length === 0
        ) {
          recurrenceDates.push(Timestamp.fromDate(date));
        }
      }
      currentDate = addDays(currentDate, 7);
    }

    return recurrenceDates;
  }

  return (
    <Container>
      <h3
        className="section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Créneaux
        <LoadingButton className="btn btn-custom2" onClick={ajoutCreneau}>
          Ajouter un créneau
        </LoadingButton>
      </h3>
      <Container style={{ marginBottom: "20px" }}>
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
          {loading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
              <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            : creneaux.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>Aucun créneau</span>
            ) : (
              creneaux.map((creneau, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      boxShadow: "0px 0px 10px 2px gray",
                      marginBottom: "10px",
                      backgroundColor: "white"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        paddingRight: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginBottom: "5px"
                        }}
                      >
                        <b>
                          <span style={{
                            flex: "1 1 0",
                            backgroundColor: "#e2f0e2",
                            padding: "5px",
                            borderRadius: "3px"
                          }}>{creneau.type}</span>
                        </b>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            flexDirection: "column",
                            flexWrap: "wrap",
                          }}
                        >

                          <span style={{
                            margin: "0px 0px",
                            marginBottom: "5px"
                          }}>
                            Tous les <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "5px",
                            }}>{DaysOfWeek[creneau.jour]}s</b>
                          </span>

                          <span
                            style={{ textWrap: "nowrap", margin: "0px 0px" }}
                          >
                            de {creneau.heureDebut} à {creneau.heureFin}
                          </span>
                        </div>
                      </div>
                      <span>à la {creneau.lieu}</span>
                    </div>

                    <div
                      className="btn btn-custom1"
                      onClick={(e) => {
                        modifyCreneau(creneau);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                      />
                    </div>
                  </div>

                );
              })
            )}
        </Accordion>
      </Container>
      {db.isAdmin ?
        <Container>
          <h3
            className="section"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Génération
          </h3>
          <div
            style={{
              width: "100%",
            }}
          >
            <LoadingButton
              className="btn btn-custom2"
              style={{ width: "100%" }}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
              onClickPromise={analyze}
            >
              <FontAwesomeIcon
                icon={faSyncAlt}
                style={{
                  marginRight: "5px"
                }}
              />
              Analyser
            </LoadingButton>
            <Form
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 0px",
                width: "100%"
              }}>
                <FloatingLabel
                  label="Nb sem. avant"
                  style={{ flex: "1 1 auto" }}
                >
                  <Form.Control
                    type="number"
                    step="1"
                    min="0"
                    max="8"
                    style={{ width: "100%" }}
                    value={nbWeekBefore}
                    required
                    onChange={(e) => {
                      setNbWeekBefore(e.target.value);
                      setIsAnalyzed(false);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label="Nb sem. après"
                  style={{ flex: "1 1 auto" }}
                >
                  <Form.Control
                    type="number"
                    step="1"
                    min="0"
                    max="104"
                    style={{ width: "100%" }}
                    value={nbWeekAfter}
                    required
                    onChange={(e) => {
                      setNbWeekAfter(e.target.value)
                      setIsAnalyzed(false);
                    }}
                  />
                </FloatingLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "10px 0px",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingButton
                    className="btn btn-custom2"
                    style={{ width: "100px" }}
                    onClick={() => {
                      handleShowConfirmation(
                        "Générer les entraînements",
                        `Voulez-vous générer les ${trainingsToGenerate.reduce(
                          (accumulateur, t) =>
                            accumulateur + t["trainingsToGenerate"].length,
                          0
                        )} entraînements manquants ?`,
                        "success",
                        "Générer",
                        "Annuler",
                        null,
                        onValidGenerateTraining
                      );
                    }}
                    disabled={
                      (trainingsToGenerate && trainingsToGenerate.reduce(
                        (accumulateur, t) =>
                          accumulateur + t["trainingsToGenerate"].length,
                        0
                      ) === 0) || !isAnalyzed
                    }
                  >
                    Générer
                  </LoadingButton>
                  <span style={{ fontSize: "0.7em", fontStyle: "italic" }}>
                    {(isAnalyzed && trainingsToGenerate) ? trainingsToGenerate.reduce(
                      (accumulateur, t) =>
                        accumulateur + t["trainingsToGenerate"].length,
                      0
                    ) + " entraînement(s) manquant(s)"
                      : <></>}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingButton
                    className="btn btn-custom5"
                    onClick={() => {
                      handleShowConfirmation(
                        "Supprimer les entraînements",
                        `Voulez-vous supprimer les ${trainingsToDelete.length} entraînements en dehors des périodes ?`,
                        "danger",
                        "Supprimer",
                        "Annuler",
                        null,
                        onValidDeleteTraining
                      );
                    }}
                    disabled={
                      (trainingsToDelete && trainingsToDelete.length === 0) || !isAnalyzed
                    }
                  >
                    Supprimer
                  </LoadingButton>
                  <span style={{ fontSize: "0.7em", fontStyle: "italic" }}>
                    {
                      (isAnalyzed && trainingsToDelete) ? trainingsToDelete.length + " entraînement(s) en trop" : <></>
                    }
                  </span>
                </div>
              </div>

            </Form>
          </div>
        </Container> : null}

      <ModalCreneau
        show={modalCreneauShow}
        creneau={currentCreneau}
        onHide={() => setModalCreneauShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container>
  );
}

export default ManagementCreneaux;

import React, { useState, useEffect } from "react";
import { Modal, FloatingLabel, Form, ButtonGroup } from "react-bootstrap";
import LoadingButton from "../LoadingButton.js";
import { useGlobalContext } from "../GlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getTournois } from "./ModelTournois.js";
import { Joueur } from "./ModelTournois.js";

function ModalJoueur({
  show,
  currentJoueurEdit,
  onHide,
  onValid,
  onCancel,
}) {
  const {
    /*db,*/
    user,
    /*getLicencie,
    getLicencies,
    handleShowAlert,*/
    handleShowConfirmation,
    /*handleCloseConfirmation,
    onShowModalSelection,*/
  } = useGlobalContext();
  const [displayName, setDisplayName] = useState(
    currentJoueurEdit ? currentJoueurEdit.displayName : ""
  );

  const objTournois = getTournois();

  const [level, setLevel] = useState(
    currentJoueurEdit ? currentJoueurEdit.level : objTournois.parameters.levels[0]
  );
  const [gender, setGender] = useState(
    currentJoueurEdit
      ? currentJoueurEdit.genderMale
        ? "male"
        : "female"
      : "male"
  );


  const levels = objTournois.parameters.levels.map((curLevel, index) => {
    return (
      <LoadingButton
        key={index}
        variant="secondary"
        className={level === curLevel ? "buttonChecked" : ""}
        onClick={() => setLevel(level)}
      >
        {level}
      </LoadingButton>
    );
  });

  useEffect(() => {
    if (show) {
      if (currentJoueurEdit) {
        setDisplayName(currentJoueurEdit.displayName || "");
        setGender(currentJoueurEdit.genderMale ? "male" : "female");
        setLevel(currentJoueurEdit.level ? currentJoueurEdit.level : levels[0])
      } else {
        setDisplayName("");
        setGender("male");
        setLevel(levels[0])
      }
    }
  }, [currentJoueurEdit, show, levels]);

  const onValidAjout = async () => {
    try {
      const newJoueur = new Joueur();
      onValid(newJoueur);
    } catch (error) {
      console.log("Error add joueur", error);
    }
  };

  const handleUpdateJoueur = async () => {
    try {

    } catch (error) {
      console.log("Error edit licencie", error);
    }
  };


  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {currentJoueurEdit
            ? "Modification d'un joueur"
            : "Ajout d'un joueur"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Check
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
              type="radio"
              id="male"
              label="Homme"
              value="male"
              name="gender"
              checked={gender === "male"}
              onChange={(e) => {
                setGender("male");
              }}
            />
            <Form.Check
              type="radio"
              label="Femme"
              id="female"
              name="gender"
              value="female"
              checked={gender === "female"}
              onChange={(e) => {
                setGender("female");
              }}
            />
          </div>

          <FloatingLabel
            label="Pseudo"
            className={displayName === "" ? "mb-3 required" : "mb-3"}
            style={{ width: "100%" }}
          >
            <Form.Control
              type="text"
              value={displayName}
              placeholder="john"
              onChange={(e) => setDisplayName(e.target.value)}
              required
            />
          </FloatingLabel>
          <ButtonGroup style={{ marginBottom: "10px" }}>
            {levels}
          </ButtonGroup>

        </Form>

      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {currentJoueurEdit ? (
              <LoadingButton
                className="btn btn-custom5"
                disabled={user.uid === currentJoueurEdit.uid}
                onClick={() => {
                  handleShowConfirmation(
                    "",
                    "Etes vous sur de vouloir retirer cette personne du club ?",
                    "danger",
                    "Retirer du club",
                    "Annuler",
                    () => { }
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                />
                Retirer du club
              </LoadingButton>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton className="btn btn-custom1" onClick={onCancel}>
              Annuler
            </LoadingButton>
            <LoadingButton
              className="btn btn-custom2"
              onClickPromise={
                currentJoueurEdit ? handleUpdateJoueur : onValidAjout
              }
              disabled={displayName === ""}
            >
              {currentJoueurEdit ? "Enregistrer" : "Ajouter"}
            </LoadingButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal >
  );
}

export default ModalJoueur;

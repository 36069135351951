import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  FloatingLabel,
  Form,
  ButtonGroup,
  Container
} from "react-bootstrap";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";
import LoadingButton from "./LoadingButton.js";
import { useGlobalContext } from "./GlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

function ModalEquipe({ show, equipe, onHide, onValid, onCancel }) {
  const {
    db,
    getLicencie,
    getLicencies,
    LevelType,
    handleShowConfirmation,
    handleCloseConfirmation,
    onShowModalSelection
  } = useGlobalContext();
  const [name, setName] = useState("");
  const [level, setLevel] = useState("");
  const [capitaines, setCapitaines] = useState([]);
  const [joueurs, setJoueurs] = useState([]);

  const getCapitaines = useCallback(async (equipe) => {
    const retour = [];
    for (const encUid of equipe["capitaines"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  const getJoueurs = useCallback(async (equipe) => {
    const retour = [];
    for (const encUid of equipe["joueurs"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);

  useEffect(() => {
    let unsubscribeEquipe = null;
    if (show) {
      if (equipe) {
        const retour = [];
        const q = query(collection(db.db, "clubs/badlevier/équipes"), where("uid", "==", equipe.uid), limit(1));
        unsubscribeEquipe = onSnapshot(q, (querySnapshotEquipe) => {
          querySnapshotEquipe.forEach(async (doc) => {
            const equipe = doc.data();
            const equipeFindIndex = retour.findIndex(equ => equ.uid === equipe.uid);
            if (equipeFindIndex > -1) {
              retour[equipeFindIndex] = equipe;
            } else {
              retour.push(equipe);
            }
            setCapitaines(await getCapitaines(equipe));
            setJoueurs(await getJoueurs(equipe));
            setName(equipe.name || "");
            setLevel(equipe.level || "");
          });
        });
      }
    }
    return () => {
      if (unsubscribeEquipe) unsubscribeEquipe();
    };
  }, [show, equipe, db, getCapitaines, getJoueurs]);

  const onValidAjout = async () => {
    try {
      if (equipe) {
        await db.addEquipe({ name, level });
      } else {
        await db.addEquipe({ name, level, capitaines: capitaines.map(user => user.uid), joueurs: joueurs.map(user => user.uid) });
      }
      onValid();
    } catch (error) {
      console.log("Error create equipe", error);
    }
  };

  const handleUpdateEquipe = async () => {
    try {
      if (equipe) {
        await db.updateEquipe(equipe.uid, {
          name,
          level,
        });
      } else {
        await db.updateEquipe(equipe.uid, {
          name,
          level,
          capitaines: capitaines.map(user => user.uid), joueurs: joueurs.map(user => user.uid)
        });
      }

      onValid();
    } catch (error) {
      console.log("Error edit equipe", error);
    }
  };

  const handleValidDeleteEquipe = async () => {
    try {
      await db.removeEquipe(equipe.uid);
      onValid();
      handleCloseConfirmation();
    } catch (error) {
      console.log("Error delete equipe", error);
    }
  };

  const ajoutCapitaine = async (selectedCapitaines) => {
    try {
      if (selectedCapitaines.length !== 1)
        console.error("Plusieurs users sélectionné");
      const uid = selectedCapitaines[0];
      if (equipe) {
        await db.addCapitaine(equipe.uid, uid);
      } else {
        setCapitaines([...capitaines, await getLicencie(uid)]);
      }
    } catch (error) {
      console.log("Error ajout participant", error);
    }
  }

  const handleAjoutCapitaine = async () => {
    const licencies = await getLicencies();
    onShowModalSelection(
      ajoutCapitaine,
      "Ajouter un capitaine",
      capitaines.map(u => u.uid),
      () => licencies
    );
  }

  const ajoutJoueur = async (selectedJoueurs) => {
    try {
      if (selectedJoueurs.length !== 1)
        console.error("Plusieurs users sélectionné");
      const uid = selectedJoueurs[0];
      if (equipe) {
        await db.addJoueur(equipe.uid, uid);
      } else {
        setJoueurs([...joueurs, await getLicencie(uid)]);
      }

    } catch (error) {
      console.log("Error ajout participant", error);
    }
  }

  const handleAjoutJoueur = async () => {
    const licencies = await getLicencies();
    onShowModalSelection(
      ajoutJoueur,
      "Ajouter un joueur",
      joueurs.map(u => u.uid),
      () => licencies
    );
  }

  const onValidRemoveCapitaine = async (equipe, userUid) => {
    await db.removeCapitaine(equipe.uid, userUid);
    handleCloseConfirmation();
  }

  const removeCapitaine = async (equipe, userUid) => {
    const licencie = await getLicencie(userUid);
    handleShowConfirmation(
      "Retirer un joueur",
      `Voulez-vous retirer ce capitaine '${licencie.displayName}' de cette équipe ?`,
      "danger",
      "Retirer",
      "Annuler",
      null,
      () => onValidRemoveCapitaine(equipe, userUid)
    );
  };

  const onValidRemoveJoueur = async (equipe, userUid) => {
    await db.removeJoueur(equipe.uid, userUid);
    handleCloseConfirmation();
  }

  const removeJoueur = async (equipe, userUid) => {
    const licencie = await getLicencie(userUid);
    handleShowConfirmation(
      "Retirer un joueur",
      `Voulez-vous retirer '${licencie.displayName}' de cette équipe ?`,
      "danger",
      "Retirer",
      "Annuler",
      null,
      () => onValidRemoveJoueur(equipe, userUid)
    );
  };

  const levelEquipe = [
    LevelType.D1,
    LevelType.D2,
    LevelType.D3
  ].map((currentLevel, index) => {
    return (
      <LoadingButton
        key={index}
        variant="secondary"
        className={currentLevel === level ? "buttonChecked" : ""}
        onClick={() => setLevel(currentLevel)}
      >
        {currentLevel}
      </LoadingButton>
    );
  });

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {equipe ? "Modification d'un équipe" : "Ajout d'un équipe"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <FloatingLabel
            label="Nom de l'équipe"
            className="mb-3"
            style={{ width: "100%" }}
          >
            <Form.Control
              type="text"
              style={{ width: "100%" }}
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </FloatingLabel>
          <ButtonGroup style={{ marginBottom: "10px" }}>
            {levelEquipe}
          </ButtonGroup>
          <div
            className="section"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px"
            }}
          >
            <div>
              <b>Capitaines</b>
              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "0.8em",
                  marginLeft: "10px",
                }}
              >
                ({capitaines.length})
              </span>
            </div>
            <LoadingButton className="btn btn-custom2" onClickPromise={handleAjoutCapitaine}>
              Ajouter un capitaine
            </LoadingButton>
          </div>

          <Container>
            {capitaines.length === 0 ? (
              <span>Aucun capitaine</span>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {capitaines.map((capitaine, index) => {
                  return (
                    <span
                      key={index}
                      style={{ textWrap: "nowrap", margin: "0px 10px", border: "solid 1px green", borderRadius: "3px", padding: "2px 5px" }}
                    >
                      {capitaine.firstName} {capitaine.lastName}
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          fontSize: "1em",
                          marginLeft: "5px",
                          color: "red",
                          cursor: "pointer"
                        }}
                        onClick={() => removeCapitaine(equipe, capitaine.uid)}
                      />
                    </span>
                  )
                })}
              </div>
            )}
          </Container>

          <div
            className="section"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px"
            }}
          >
            <div>
              <b>Joueurs</b>

              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "0.8em",
                  marginLeft: "10px",
                }}
              >
                ({joueurs.length})
              </span>
            </div>
            <LoadingButton className="btn btn-custom2" onClick={handleAjoutJoueur}>
              Ajouter un joueur
            </LoadingButton>
          </div>

          <Container>
            {joueurs.length === 0 ? (
              <span>Aucun joueur</span>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {joueurs.map((joueur, index) => {
                  return (
                    <span
                      key={index}
                      style={{ textWrap: "nowrap", margin: "0px 10px", border: "solid 1px green", borderRadius: "3px", padding: "2px 5px" }}
                    >
                      {joueur.displayName}
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          fontSize: "1em",
                          marginLeft: "5px",
                          color: "red",
                          cursor: "pointer"
                        }}
                        onClick={() => removeJoueur(equipe, joueur.uid)}
                      />
                    </span>
                  )
                })}
              </div>
            )}
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {equipe ? (
            <LoadingButton
              className="btn btn-custom5"
              onClick={() => {
                handleShowConfirmation(
                  "",
                  "Etes vous sur de vouloir retirer cette équipe ?",
                  "danger",
                  "Retirer un équipe",
                  "Annuler",
                  handleValidDeleteEquipe
                );
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
              />
              Retirer
            </LoadingButton>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <LoadingButton className="btn btn-custom1" onClick={onCancel}>
              Annuler
            </LoadingButton>
            <LoadingButton
              className="btn btn-custom2"
              onClickPromise={equipe ? handleUpdateEquipe : onValidAjout}
              disabled={
                name === "" ||
                level === ""
              }
            >
              {equipe ? "Enregistrer" : "Ajouter"}
            </LoadingButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEquipe;

import React, { useState, useEffect } from "react";
import { Container, Accordion, Placeholder } from "react-bootstrap";
import { useGlobalContext } from "../GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Tournoi, /*StateTournoi, Tour,*/ getTournois, /*getTournoi*/ } from "./ModelTournois.js";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../LoadingButton.js";

function Generator({ style }) {
  const { user, PageUrl, getLicencie, getDateTournoi, handleCloseConfirmation, handleShowConfirmation, getMiniUserPresentation } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tournois, setTournois] = useState(null);



  const ajoutTournoi = () => {
    const objTournois = getTournois();
    const newTournoi = new Tournoi();
    newTournoi.name = "Nouveau tournoi";
    newTournoi.date = Date.now();
    newTournoi.createur = user ? user.uid : null;
    objTournois.tournois.push(newTournoi);
    objTournois.save();
    setTournois(objTournois);
  };

  const deleteTournoi = async (uid) => {
    const objTournois = tournois;
    const indexOf = objTournois.tournois.findIndex(
      (tournoi) => tournoi.uid === uid
    );
    if (indexOf !== -1) {
      objTournois.tournois.splice(indexOf, 1);
    }
    objTournois.save();
    setTournois(objTournois);
    handleCloseConfirmation();
  }

  useEffect(() => {
    setTournois(getTournois());
    setLoading(false);
  }, []);

  return (
    <div id="generatorMain" style={style}>
      <Container>
        <h3
          className="section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Tournois
          <LoadingButton className="btn btn-custom2" onClick={ajoutTournoi}>
            Ajouter un tournoi
          </LoadingButton>
        </h3>
        <Container style={{ marginBottom: "20px" }}>
          <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
            {loading ?
              <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
              </Placeholder>
              : tournois.tournois.length === 0 ? (
                <div style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <span style={{ fontStyle: "italic" }}>Aucun tournoi</span>

                </div>
              ) : (
                tournois.tournois.map((tournoi, index) => {
                  const joueurs = tournoi["joueurs"];
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", flex: "1 1 auto", flexDirection: "column" }}>
                              <b>
                                <span style={{ flex: "1 1 0" }}>{tournoi.name}</span>
                              </b>
                              <span>{tournoi.state}</span>
                            </div>
                            <div
                              className="btn btn-custom1"
                              onClick={(e) => {
                                navigate(PageUrl.GENERATOR_TOURNOI + "/" + tournoi.uid, { to: PageUrl.GENERATOR_TOURNOI });
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="32px"
                                width="24px"
                                viewBox="0 0 512 512"
                                style={{ marginRight: "10px" }}
                              >
                                <path
                                  fill="rgb(28, 105, 28)"
                                  d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                                />
                              </svg>
                              Modifier
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div style={{ width: "95%", margin: "10px", marginTop: "0px" }}>
                          <label>
                            Créé le
                            <span>{getDateTournoi(tournoi.date)}</span>
                          </label>
                          <label>
                            Créé par
                            <span>{(user && tournoi.createur) ? (tournoi.createur === user.uid) ? "Vous" : getLicencie(tournoi.createur).displayName : "-"}</span>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginBottom: "10px",
                              backgroundColor: "rgb(230, 230, 230)",
                              padding: "10px",
                              borderRadius: "3px"
                            }}
                          >
                            <div>
                              Joueurs
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "0.8em",
                                  marginLeft: "10px",
                                }}
                              >
                                ({joueurs.length})
                              </span>
                            </div>
                          </div>

                          <Container
                            key={index}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexWrap: "wrap"
                            }}
                          >
                            {joueurs.length === 0 ? (
                              <span>Aucun joueur</span>
                            ) : (
                              joueurs.map((joueur, index) => {
                                return (
                                  getMiniUserPresentation(
                                    joueur,
                                    "",
                                    index
                                  )
                                );
                              })
                            )}
                          </Container>
                        </div>
                        <div
                          style={{ width: "100%" }}
                          className="btn btn-custom5"
                          onClick={() => {
                            handleShowConfirmation(
                              "",
                              "Etes vous sur de vouloir supprimer ce tournoi ?",
                              "danger",
                              "Supprimer",
                              "Annuler",
                              null,
                              () => deleteTournoi(tournoi.uid)
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                          />
                          Supprimer
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              )}
          </Accordion>
        </Container>
      </Container >
    </div>
  );
}

export default Generator;

import React, { useState, useEffect } from "react";
import { Modal, Form, Container, Spinner } from "react-bootstrap";
import LoadingButton from "./LoadingButton";

function ModalSelection({
  onHide,
  show,
  onValidSelection,
  onCancelSelection,
  header,
  getItems,
  selectedItems,
  selectionMultiple,
  contentKeys,
  uidKey,
}) {
  const [selectedItems2, setSelectedItems2] = useState(selectedItems);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const onValid = (list) => {
    if (onValidSelection) onValidSelection(list);
    onHide();
  };

  const onChangeSelection = (e, item) => {
    let list = selectedItems2;
    if (!selectionMultiple) list = [];
    if (e.target.checked) {
      list.push(item[uidKey]);
    } else {
      list.splice(item[uidKey], 1);
    }
    setSelectedItems2(list);
    if (!selectionMultiple) onValid(list);
  };

  useEffect(() => {
    setSelectedItems2(selectedItems);
    if (getItems) setItems(getItems());
    setLoading(false);
  }, [selectedItems, getItems]);

  if (loading) {
    return <Spinner animation="border" role="status"></Spinner>;
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {items &&
            items.map((item, index) => {
              return (
                <label
                  className="list1"
                  key={index}
                  name={"input" + index}
                  style={{ cursor: "pointer" }}
                >
                  <Form.Check
                    type={selectionMultiple ? "checkbox" : "radio"}
                    checked={selectedItems2.includes(item[uidKey])}
                    onChange={(e) => onChangeSelection(e, item)}
                    id={"input" + index}
                    disabled={
                      selectedItems2.includes(item[uidKey]) &&
                      !selectionMultiple
                    }
                  ></Form.Check>
                  <div style={{ display: "flex", alignItems: "center", flex: "1 1 auto", justifyContent: "space-between" }}>
                    {(contentKeys.length >= 1) && (
                      <span style={{ flex: "1 1 auto", fontWeight: "bold" }}>{item[contentKeys[0]]}</span>
                    )}
                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "flex-end" }}>
                      {(contentKeys.length >= 2) && (
                        <span style={{}}>{item[contentKeys[1]]}</span>
                      )}
                      {(contentKeys.length >= 3) && (
                        <span style={{ marginLeft: "5px" }}>{item[contentKeys[2]]}</span>
                      )}
                    </div>
                  </div>

                </label>
              );
            })}
        </Container>
      </Modal.Body>
      {selectionMultiple ? (
        <Modal.Footer>
          <LoadingButton className="btn btn-custom1" onClick={onCancelSelection}>
            Annuler
          </LoadingButton>
          <LoadingButton
            className="btn btn-custom2"
            onClick={() => onValid(selectedItems)}
          >
            Ajouter
          </LoadingButton>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}

export default ModalSelection;

import React, { useState } from "react";
import LoadingButton from "./LoadingButton";

function RemiseMembre({ membresBureau, remiseMembre, changeRemiseMembre }) {
  const [remiseMembreBureau, setRemiseMembreBureau] = useState(remiseMembre);
  return (membresBureau.map((membre, index) => {
    return (
      <LoadingButton
        key={index}
        style={{ height: "35px" }}
        variant="secondary"
        className={remiseMembreBureau && membre.uid === remiseMembreBureau.uid ? "buttonChecked" : ""}
        onClick={() => {
          setRemiseMembreBureau(membre);
          changeRemiseMembre(membre);
        }}
      >
        {membre.displayName}
      </LoadingButton>
    );
  }));
}

export default RemiseMembre;

import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Accordion, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ModalEvent from "./ModalEvent.js";
import LoadingButton from "./LoadingButton.js";
import {
  onSnapshot,
  query,
  collection,
} from "firebase/firestore";
function ManagementEvents() {
  const {
    db,
    eventItem,
    getPhotoEvent,
    handleShowConfirmation,
    handleCloseConfirmation,
    getDateTraining
  } = useGlobalContext();
  const [events, setEvents] = useState([]);
  const [currentEventEdit, setCurrentEventEdit] = useState(null);
  const [modalEventShow, setModalEventShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const modifyEvent = (eventItem) => {
    setCurrentEventEdit(eventItem);
    setModalEventShow(true);
  };
  const ajoutEvent = () => {
    setCurrentEventEdit(null);
    setModalEventShow(true);
  };

  const onValid = () => {
    setModalEventShow(false);
  };

  const onCancel = () => {
    setModalEventShow(false);
  };

  const removeAllEvents = async () => {
    try {
      for (let i = 0; i < events.length; i++) {
        const currentEvent = events[i];
        if (currentEvent.uid !== eventItem.uid) {
          await db.removeEvent(currentEvent.uid);
          events.splice(i, 1);
          i--;
        }
      }
      handleCloseConfirmation();
    } catch (error) {
      console.error("Problème pour supprimer tous les events");
      return false;
    }
  };

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/events"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const retour = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          retour.push(doc.data());
        })
      );
      setEvents(retour);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [db]);

  return (
    <Container>
      <div
        className="section"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <LoadingButton className="btn btn-custom2" onClick={ajoutEvent}>
            Ajouter un évènement
          </LoadingButton>
          <span
            style={{
              fontStyle: "italic",
              fontSize: "0.8em",
              marginLeft: "10px",
            }}
          >
            <b>{events.length} évènement{events.length > 1 && "s"}</b>
          </span>
        </div>

      </div>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : events.length !== 0 && (
            <>
              <Accordion
                defaultActiveKey="0"
                style={{ maxWidth: "600px", width: "100%" }}
              >
                {events.map((eventItem, index) => {
                  return (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      style={{
                      }}
                    >
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              flex: "1 1 auto",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                flex: "1 1 auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                opacity: eventItem.visible ? 1 : 0.5
                              }}
                            >
                              <span>
                                {eventItem.title === "" ? <span style={{ fontStyle: "italic" }}>Pas de titre</span> : <b>{eventItem.title}</b>}
                              </span>
                              <span>
                                {eventItem.dateDebut !== "" && eventItem.dateDebut === eventItem.dateFin ?
                                  getDateTraining(eventItem.dateDebut)
                                  :
                                  <>
                                    {eventItem.dateDebut !== "" && (
                                      <>
                                        <span>Du <b>{getDateTraining(eventItem.dateDebut)}</b></span>
                                        <br />
                                      </>
                                    )}
                                    {eventItem.dateFin !== "" && (
                                      <span>Au <b>{getDateTraining(eventItem.dateFin)}</b></span>
                                    )}
                                  </>
                                }
                              </span>
                              {eventItem.heure &&
                                <span style={{ marginBottom: "5px" }}>à {eventItem.heure.replace(":", "h")}</span>
                              }
                            </div>


                          </div>
                          <div
                            className="btn btn-custom1"
                            onClick={(e) => {
                              modifyEvent(eventItem);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                            />
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          {eventItem.photoUrl &&
                            getPhotoEvent(eventItem.photoUrl, eventItem.title, {
                              maxHeight: "150px",
                              maxWidth: "150px",
                              height: "100%",
                              width: "100%",
                              borderRadius: "5px",
                            })
                          }
                          <span>{eventItem.description}</span>
                          <div style={{ margin: "10px 0px", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            {false && <div
                              className="btn btn-custom2"
                              onClick={(e) => {
                                modifyEvent(eventItem);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                              />
                            </div>
                            }

                          </div>

                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          )}

      </Container>
      {
        false && db.isAdmin && events.length > 0 ? (
          <div
            className="section"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <LoadingButton
              className="btn btn-custom5"
              onClick={() => {
                handleShowConfirmation(
                  "Retirer tous les events",
                  `Etes-vous sûr de vouloir retirer tous les events`,
                  "danger",
                  "Tout retirer",
                  "Annuler",
                  null,
                  removeAllEvents
                );
              }}
            >
              Retirer tous les events
            </LoadingButton>
          </div>
        ) : null
      }
      <ModalEvent
        show={modalEventShow}
        currentEventEdit={currentEventEdit}
        onHide={() => setModalEventShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container >
  );
}

export default ManagementEvents;

import React, { useState, useEffect } from "react";
import { Form, Placeholder, Container, ButtonGroup, Tabs, Tab, Badge, FloatingLabel, Spinner, Accordion } from "react-bootstrap";
import { useGlobalContext } from "./GlobalContext";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import LoadingButton from "./LoadingButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faInfo, faShoppingCart, faWallet, faCheck } from "@fortawesome/free-solid-svg-icons";
import StatusTransaction from "./StatusTransaction.js";
import ModalMouvement from "./ModalMouvement.js";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  limit
} from "firebase/firestore";
import ModeReglement from "./ModeReglement.js";
import RemiseMembre from "./RemiseMembre.js";
function EditProfil() {
  const {
    PageUrl,
    handleShowAlert,
    handleShowConfirmation,
    handleCloseConfirmation,
    db,
    user,
    getLicencie,
    auth,
    getPhoto,
    getDateTraining,
    getDateShort,
    TypeReglement,
    StatusMouvement,
    TypeMouvement,
    resizeImage,
    setUser
  } = useGlobalContext();
  const navigate = useNavigate();
  const userDisplayName = user ? user.displayName : "";
  const userFirstName = user && user.firstName ? user.firstName : "";
  const userLastName = user && user.lastName ? user.lastName : "";
  const userEmail = user ? user.email : "";
  const userLicenceNumber = user ? user.licenceNumber : "";
  const userDescription = user && user.description ? user.description : "";
  const userGenderMale = user ? user.genderMale : "";
  const [displayName, setDisplayName] = useState(userDisplayName);
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [licenceNumber, setLicenceNumber] = useState(userLicenceNumber);
  const [description, setDescription] = useState(userDescription);
  const [gender, setGender] = useState(userGenderMale ? "male" : "female");
  const [uploadingImage, setUpdloadingImage] = useState(false);
  const [canDelete, setCanDelete] = useState(
    user && user.photoURLCustom != null
  );
  const [mouvementsDepot, setMouvementsDepot] = useState([]);
  const [mouvementsDepotEncours, setMouvementsDepotEncours] = useState([]);
  const [mouvementsAchat, setMouvementsAchat] = useState([]);
  const [mouvementsAchatEncours, setMouvementsAchatEncours] = useState([]);
  //const [achatDepot, setAchatDepot] = useState([]);
  const [modalMouvementShow, setModalMouvementShow] = useState(false);
  const [currentTypeMouvement, setCurrentTypeMouvement] = useState(null);
  const [loadingMouvements, setLoadingMouvements] = useState(true);
  const [loadingMouvementsEncours, setLoadingMouvementsEncours] = useState(true);
  const [membresBureau, setMembresBureau] = useState([]);
  const [porteFeuille, setPorteFeuille] = useState(null);

  const { onglet } = useParams();
  const ongletSelected = onglet ? onglet : "informations";

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file == null) return;
    setUpdloadingImage(true);

    try {
      const resizedImageBlob = await resizeImage(file, 200); // Ajustez la taille souhaitée
      await db
        .updatePhotoURLCustom(user, resizedImageBlob)
        .then((photoCustom) => {
          user.photoURLCustom = photoCustom;
          setCanDelete(true);
          setUpdloadingImage(false);
        })
        .catch((error) => {
          console.error("Upload impossible : ", error);
          setUpdloadingImage(false);
        });
    } catch (error) {
      console.error("Erreur de redimensionnement d'image : ", error);
    }

  };

  /*const handleImageUpload = async () => {
        const storage = getStorage();
        await getDownloadURL(ref(storage, path))
            .then(async (photoURL) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    console.log("blob", blob);
                    const blobURL = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = blobURL;
                    a.download = 'Photo de profil Badlevier - ' + user.displayName; // Remplacez par le nom de fichier souhaité
                    a.click();
                    URL.revokeObjectURL(blobURL);
                };
                xhr.open('GET', photoURL);
                xhr.send();
            })
            .catch((error) => {
                console.error("Download impossible : ", error);
            });
    };*/

  const handleImageDelete = async () => {
    db.deletePhotoURLCustom(user).then(() => {
      user.photoURLCustom = null;
    });
  };

  const handleUpdateProfil = async () => {
    if (db.isLicencie) {
      await db
        .updateLicencie(user.uid, {
          displayName,
          firstName,
          lastName,
          description,
        })
        .then((user) => {
          setUser(user);
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          handleShowAlert("Profil mis à jour", "", "warning");
        })
        .catch((error) => {
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          console.error("Pb mise à jour profil", error);
        });
    } else if (db.isMemberClub) {
      await db
        .updateLicencie(user.uid, {
          displayName,
          firstName,
          lastName,
          licenceNumber,
          description,
          genderMale: gender === "male",
        })
        .then((user) => {
          setUser(user);
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          handleShowAlert("Profil mis à jour", "", "warning");
        })
        .catch((error) => {
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          console.error("Pb mise à jour profil", error);
        });
    } else {
      await db
        .updateUser(user.uid, {
          displayName,
          firstName,
          lastName,
          licenceNumber,
          description,
          genderMale: gender === "male",
        })
        .then((user) => {
          setUser(user);
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          handleShowAlert("Profil mis à jour", "", "warning");
        })
        .catch((error) => {
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
          console.error("Pb mise à jour profil", error);
        });
    }

  };
  const handleCancel = async () => {
    navigate(PageUrl.HOME, { to: PageUrl.HOME });
  };

  const handleValidSuppressionCompte = async () => {
    await db
      .deleteCompte(user, auth)
      .then(() => {
        handleCloseConfirmation();
        navigate(PageUrl.HOME, { to: PageUrl.HOME });
        handleShowAlert("Compte supprimé", "", "warning");
      })
      .catch((error) => {
        handleCloseConfirmation();
        navigate(PageUrl.HOME, { to: PageUrl.HOME });
        handleShowAlert(
          "Impossible de supprimer le compte supprimé dans l'authentifaction, reconnectez-vous et recommencer",
          "",
          "warning"
        );
      });
  };

  const onValid = () => {
    setModalMouvementShow(false);
  };

  const onCancel = () => {
    setModalMouvementShow(false);
  };

  const handleAddCommande = () => {
    setCurrentTypeMouvement(TypeMouvement.ACHAT);
    setModalMouvementShow(true);
  };

  const handleAddCreditPorteFeuille = () => {
    setCurrentTypeMouvement(TypeMouvement.DEPOT);
    setModalMouvementShow(true);
  };

  const handleDeleteMouvement = async (mouvementUid) => {
    try {
      await db.removeMouvement(mouvementUid);
      onValid();
      handleCloseConfirmation();
    } catch (error) {
      console.log("Error delete mouvement", error);
    }
  };

  const handleValidPaiement = async (mouvement) => {
    const newStatus = mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? StatusMouvement.ATTENTE_DISTRIBUTION :
      (mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE ? StatusMouvement.ATTENTE_VALIDATION : StatusMouvement.ATTENTE_DEPOT);
    await db.updateMouvement(mouvement.uid, {
      status: newStatus,
      typeReglement: mouvement.typeReglement,
      remiseMembreBureau: mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau.uid : null
    });
    if (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE) {
      const newMontant = (porteFeuille - mouvement.montant);
      await db.updateLicencie(mouvement.licencie.uid, {
        "porte-feuille": newMontant
      });
      await db.addMouvement(
        {
          achatUid: mouvement.uid,
          panier: mouvement.panier,
          typeMouvement: TypeMouvement.DEPOT,
          motif: "Commande",
          typeReglement: mouvement.typeReglement,
          montant: -mouvement.montant,
          status: StatusMouvement.CLOTURE,
          remiseMembreBureau: null,
          licencie: mouvement.licencie.uid,
          createur: user.uid
        }, true);
    }
    handleCloseConfirmation();
  };

  const onNotPaiementMouvement = async (mouvement) => {
    const lic = mouvement.licencie;
    await db.updateMouvement(mouvement.uid, {
      status: StatusMouvement.ATTENTE_PAIEMENT,
    });
    if (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE) {
      const newMontant = (lic["porte-feuille"] + mouvement.montant);
      await db.updateLicencie(mouvement.licencie.uid, {
        "porte-feuille": newMontant
      });
      await db.addMouvement(
        {
          achatUid: mouvement.uid,
          panier: mouvement.panier,
          typeMouvement: TypeMouvement.DEPOT,
          motif: "Retour commande",
          typeReglement: mouvement.typeReglement,
          montant: mouvement.montant,
          status: StatusMouvement.CLOTURE,
          remiseMembreBureau: null,
          licencie: mouvement.licencie.uid,
          createur: user.uid
        }, true);
    }
    handleCloseConfirmation();
  };

  useEffect(() => {
    if (user === null) return;
    let unsubscribePorteFeuille = null;
    if (user) {
      const q3 = query(collection(db.db, "clubs/badlevier/licenciés"), where("uid", "==", user.uid), limit(1));
      unsubscribePorteFeuille = onSnapshot(q3, async (querySnapshot) => {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const currentUser = doc.data();
            setPorteFeuille(currentUser["porte-feuille"]);
          }));
      });
    }

    const q = query(collection(db.db, "clubs/badlevier/licenciés/" + user.uid + "/mouvements"));
    //const q = query(collection(db.db, "clubs/badlevier/licenciés/" + user.uid + "/mouvements"));
    const unsubscribeMouvements = onSnapshot(q, async (querySnapshotMouvemements) => {
      let retourDepot = [];
      let retourAchat = [];
      //const retourAchatDepot = [];
      await Promise.all(
        querySnapshotMouvemements.docs.map(async (doc) => {
          const mouvement = doc.data();
          mouvement["licencie"] = await getLicencie(mouvement.licencie);
          mouvement["createur"] = await getLicencie(mouvement.createur);
          mouvement["remiseMembreBureau"] = await getLicencie(mouvement.remiseMembreBureau);
          mouvement["distribuePar"] = await getLicencie(mouvement.distribuePar);
          if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
            const mouvementFindIndex = retourDepot.findIndex(mouv => mouv.uid === mouvement.uid);
            if (mouvementFindIndex > -1) {
              retourDepot[mouvementFindIndex] = mouvement;
            } else {
              retourDepot.push(mouvement);
            }
          } else {
            const mouvementFindIndex = retourAchat.findIndex(mouv => mouv.uid === mouvement.uid);
            if (mouvementFindIndex > -1) {
              retourAchat[mouvementFindIndex] = mouvement;
            } else {
              retourAchat.push(mouvement);
            }
            /*if (mouvement.status === StatusMouvement.ATTENTE_DISTRIBUTION || mouvement.status === StatusMouvement.CLOTURE) {
              retourAchatDepot.push(mouvement);
            }*/
          }

        }));

      retourDepot = retourDepot.sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1);
      retourAchat = retourAchat.sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1);

      //setAchatDepot([...retourAchatDepot]);
      setMouvementsDepot([...retourDepot]);
      setMouvementsAchat([...retourAchat]);
      setLoadingMouvements(false);
    });
    const q1 = query(collection(db.db, "clubs/badlevier/mouvements"), where("licencie", "==", user.uid));
    //const q = query(collection(db.db, "clubs/badlevier/licenciés/" + user.uid + "/mouvements"));
    const unsubscribeMouvementsEncours = onSnapshot(q1, async (querySnapshotMouvemementsEncours) => {
      let retour2Depot = [];
      let retour2Achat = [];
      //const retour2AchatDepot = [];
      await Promise.all(
        querySnapshotMouvemementsEncours.docs.map(async (doc) => {
          const mouvement = doc.data();
          mouvement["licencie"] = await getLicencie(mouvement.licencie);
          mouvement["createur"] = await getLicencie(mouvement.createur);
          mouvement["remiseMembreBureau"] = await getLicencie(mouvement.remiseMembreBureau);
          mouvement["distribuePar"] = await getLicencie(mouvement.distribuePar);
          if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
            const mouvementFindIndex = retour2Depot.findIndex(mouv => mouv.uid === mouvement.uid);
            if (mouvementFindIndex > -1) {
              retour2Depot[mouvementFindIndex] = mouvement;
            } else {
              retour2Depot.push(mouvement);
            }
          } else {
            const mouvementFindIndex = retour2Achat.findIndex(mouv => mouv.uid === mouvement.uid);
            if (mouvementFindIndex > -1) {
              retour2Achat[mouvementFindIndex] = mouvement;
            } else {
              retour2Achat.push(mouvement);
            }
            /*if (mouvement.status === StatusMouvement.ATTENTE_DISTRIBUTION || mouvement.status === StatusMouvement.CLOTURE) {
              retour2AchatDepot.push(mouvement);
            }*/
          }
        }));
      //setAchatDepot([...retour2AchatDepot]);
      retour2Depot = retour2Depot.sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1);
      retour2Achat = retour2Achat.sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1);
      setMouvementsDepotEncours([...retour2Depot]);
      setMouvementsAchatEncours([...retour2Achat]);
      setLoadingMouvementsEncours(false);
    });


    const q4 = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeBureau = onSnapshot(q4, async (doc) => {
      const badlevier = doc.data();
      const membres = [];
      for (const membreUid of badlevier["bureau"]["membres"]) {
        membres.push(await getLicencie(membreUid));
      }
      membres.push(await getLicencie(badlevier["bureau"]["président"]));
      membres.push(await getLicencie(badlevier["bureau"]["vice-président"]));
      membres.push(await getLicencie(badlevier["bureau"]["trésorier"]));
      membres.push(await getLicencie(badlevier["bureau"]["secrétaire"]));
      setMembresBureau(membres.filter(lic => lic != null));

    });

    return () => {
      unsubscribeMouvementsEncours();
      unsubscribeMouvements();
      unsubscribeBureau();
      if (unsubscribePorteFeuille) unsubscribePorteFeuille();
    };
  }, [db, user, getLicencie, TypeMouvement, StatusMouvement]);

  if (user == null) return <Navigate to={PageUrl.HOME} />;

  if (loadingMouvements || loadingMouvementsEncours) {
    return <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
      <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
    </Placeholder>
  }

  const badgeAchatCompt = mouvementsAchatEncours.length;
  const badgeDepotCompt = mouvementsDepotEncours.length;

  return (
    <>
      <Tabs
        defaultActiveKey="informations"
        activeKey={ongletSelected}
        onSelect={(k) =>
          navigate(PageUrl.EDIT_PROFIL + "/" + k, { to: PageUrl.EDIT_PROFIL })

          //    setOngletSelected(k)
        }
        id="noanim-tab-example"
        className="mb-3"
        style={{ position: "sticky", top: "30px", zIndex: "6", backgroundColor: "lightgray" }}
        fill
      >
        <Tab eventKey="informations"
          title=<div><FontAwesomeIcon icon={faInfo} style={{ fontSize: "1em", marginRight: "5px" }} />Infos</div>
        >
          <Form className="card-custom1">
            <div style={{ width: "100%" }}>
              <div
                style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    flex: "1 1 auto",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {uploadingImage ? (
                    <Spinner
                      style={{ height: "100px", width: "100px", margin: "50px" }}
                    ></Spinner>
                  ) : (
                    getPhoto(user, {
                      maxHeight: "150px",
                      maxWidth: "150px",
                      height: "100%",
                      width: "100%",
                      borderRadius: "5px",
                    })
                  )}
                  {db.isMemberClub ? (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Form.Group controlId="formFile">
                        <Form.Label className="btn btn-custom1">
                          Modifier l'image
                        </Form.Label>
                        <Form.Control
                          style={{ display: "none" }}
                          type="file"
                          onChange={handleImageChange}
                        />
                      </Form.Group>

                      {/*<Button className="btn btn-custom1" onClick={handleImageUpload}>
                                        Télécharger
                                        </Button>*/}
                      {canDelete ? (
                        <LoadingButton
                          className="btn btn-custom1"
                          onClickPromise={handleImageDelete}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                              fontSize: "1em",
                              marginRight: "5px",
                              color: "red",
                            }}
                          />
                          Supprimer l'image
                        </LoadingButton>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: "1 1 auto",
                    flexDirection: "row",
                  }}
                >
                  <FloatingLabel
                    label="Email"
                    className="mb-3"
                    style={{ width: "100%", display: "none" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="John@gmail.com"
                      value={userEmail}
                      disabled={true}
                    />
                  </FloatingLabel>
                  {db.isMemberClub && <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-around",
                    }}
                  >
                    <Form.Check
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                      type="radio"
                      id="male"
                      label="Homme"
                      value="male"
                      name="gender"
                      checked={gender === "male"}
                      onChange={(e) => {
                        setGender("male");
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Femme"
                      id="female"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      onChange={(e) => {
                        setGender("female");
                      }}
                    />
                  </div>
                  }
                  <FloatingLabel
                    label="Pseudo"
                    className="mb-3"
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="John Doe"
                      value={displayName}
                      onChange={(e) => {
                        setDisplayName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    label="Prénom"
                    className="mb-3"
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="John Doe"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    label="Nom"
                    className="mb-3"
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="John Doe"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  {db.isMemberClub ? (
                    <FloatingLabel
                      label="Numéro de licence"
                      className="mb-3"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="123456"
                        value={licenceNumber}
                        onChange={(e) => {
                          setLicenceNumber(e.target.value);
                        }}
                        disabled={true}
                      />
                    </FloatingLabel>
                  ) : null}
                </div>
              </div>

              <FloatingLabel controlId="floatingTextarea2" label="Description">
                <Form.Control
                  className="mb-3"
                  as="textarea"
                  placeholder="Description"
                  style={{ height: "100px" }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </FloatingLabel>
            </div>
            <div
              style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
            >
              <LoadingButton className="btn btn-custom1" onClickPromise={handleCancel}>
                Annuler
              </LoadingButton>
              <LoadingButton
                className="btn btn-custom2"
                contentLoading="Enregistrement ..."
                onClickPromise={handleUpdateProfil}
              >
                Enregistrer
              </LoadingButton>
            </div>
          </Form>

          <h3 className="section">Gestion du compte</h3>
          <LoadingButton
            className="btn btn-custom5"
            style={{ width: "100%" }}
            onClick={() => {
              handleShowConfirmation(
                "Suppression du compte",
                "Toutes les données liés au compte seront perdues",
                "danger",
                "Supprimer le compte",
                "Annuler",
                null,
                handleValidSuppressionCompte
              );
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
            />
            Supprimer le compte
          </LoadingButton>
        </Tab>
        {db.isMemberClub &&
          <Tab eventKey="commandes"
            title=<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: "1em", marginRight: "5px" }} />
              <span style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
                maxWidth: badgeAchatCompt > 0 ? "65px" : "",
                marginRight: "5px"
              }}>Commandes
              </span>
              {badgeAchatCompt > 0 && <Badge bg="warning" style={{ color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeAchatCompt}</Badge>}

            </div>

          >
            <Container>
              <h3
                className="section"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              > Commandes en cours
                <LoadingButton className="btn btn-custom2" onClick={handleAddCommande} style={{ display: "none" }}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ fontSize: "1em", color: "white" }}
                  />
                </LoadingButton>
              </h3>
              <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                {mouvementsAchatEncours.length === 0 ? (
                  <Container><span style={{ fontStyle: "italic" }}>Aucune commande</span></Container>
                ) : (
                  mouvementsAchatEncours.map((mouvement, index) => {
                    //const createur = await getLicencie(mouvement.createur);
                    //const remiseMembre = await getLicencie(mouvement.remiseMembreBureau);
                    const manqueRemiseMembreBureau = mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE && mouvement.remiseMembreBureau === null;
                    let soldePorteFeuilleInsuffisant = mouvement.typeReglement === TypeReglement.PORTE_FEUILLE && porteFeuille !== null && parseFloat(mouvement.montant) > parseFloat(porteFeuille);

                    const changeTypeReglement = (currentType) => {
                      mouvement.typeReglement = currentType;
                      const indOf = mouvementsAchatEncours.findIndex(m => m.uid === mouvement.uid);
                      if (indOf > -1) {
                        mouvementsAchatEncours[indOf] = mouvement;
                      }
                      setMouvementsAchatEncours([...mouvementsAchatEncours]);
                    }

                    const changeRemiseMembre = (membre) => {
                      mouvement.remiseMembreBureau = membre;
                      const indOf = mouvementsAchatEncours.findIndex(m => m.uid === mouvement.uid);
                      if (indOf > -1) {
                        mouvementsAchatEncours[indOf] = mouvement;
                      }
                      setMouvementsAchatEncours([...mouvementsAchatEncours]);
                    }

                    let panier = null;
                    if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                      panier = mouvement.panier;
                    }
                    const nbArticlesPanier = panier ? panier.reduce((accumulateur, t) => accumulateur + t["nb"], 0) : 0;
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "10px"
                            }}
                          >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <span style={{ flex: "1 1 0", fontWeight: "bold" }}>{mouvement.motif}</span>
                              {panier && (
                                <span style={{ fontStyle: "italic" }}>{nbArticlesPanier} article{nbArticlesPanier > 1 && "s"}</span>
                              )
                              }
                            </div>
                            {mouvement.status !== StatusMouvement.CLOTURE && <span>{mouvement.status}</span>}
                            <span>{mouvement.montant}€</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{
                                          textWrap: "nowrap",
                                          textOverflow: "ellipsis",
                                          position: "relative",
                                          flex: "1 1 auto",
                                          overflow: "hidden"
                                        }}>
                                          {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                        </span>
                                        {article["flocageAvant"] !== "" &&
                                          <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                            <span>Flocage avant : </span>
                                            <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px", backgroundColor: "yellow" }}>
                                              {article["flocageAvant"]}
                                            </span>
                                          </span>
                                        }
                                      </div>

                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix * article["nb"]} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                              </div>

                              {mouvement.status === StatusMouvement.ATTENTE_PAIEMENT &&
                                <Form
                                  style={{ display: "flex", flexDirection: "column", width: "100%" }}
                                >
                                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                                      <span style={{ marginBottom: "5px", marginLeft: "5px", color: "green" }}>Mode de réglement</span>
                                      <ButtonGroup vertical style={{ marginBottom: "10px", flex: "1 1 auto" }}>
                                        <ModeReglement
                                          mouvement={mouvement}
                                          porteFeuille={porteFeuille}
                                          changeTypeReglement={changeTypeReglement}>
                                        </ModeReglement>
                                      </ButtonGroup>
                                    </div>
                                    {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                                      <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                        <span style={{ marginBottom: "5px", marginLeft: "5px", color: "green" }}>Remis à</span>
                                        <ButtonGroup vertical style={{ marginBottom: "10px" }}>
                                          <RemiseMembre
                                            membresBureau={membresBureau}
                                            remiseMembre={mouvement.remiseMembreBureau}
                                            changeRemiseMembre={changeRemiseMembre}>
                                          </RemiseMembre>
                                        </ButtonGroup>
                                      </div>
                                    }
                                  </div>

                                  {manqueRemiseMembreBureau && <span style={{ color: "darkred", textAlign: "right", marginRight: "5px" }}>Veuillez sélectionner la personne à qui vous avez remis l'argent</span>}

                                  <LoadingButton
                                    style={{ width: "100%" }}
                                    className="btn btn-custom2"
                                    disabled={manqueRemiseMembreBureau || soldePorteFeuilleInsuffisant}
                                    onClick={() => {
                                      handleShowConfirmation(
                                        "",
                                        mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ?
                                          "Vous confirmez le paiement ?" :
                                          "Vous confirmez avoir effectué le paiement ?",
                                        "success",
                                        "C'est payé",
                                        "Annuler",
                                        null,
                                        () => handleValidPaiement(mouvement)
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      style={{ fontSize: "1em", marginRight: "5px", color: "white" }}
                                    />
                                    Payer
                                  </LoadingButton>
                                </Form>
                              }

                            </>
                          )}
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{mouvement.createur === user.uid ? "Vous" : mouvement.createur.displayName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Date
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && mouvement.remiseMembreBureau.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            distribuePar={mouvement.distribuePar}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            ((mouvement.status === StatusMouvement.ATTENTE_PAIEMENT) ||
                              (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE && mouvement.status === StatusMouvement.ATTENTE_DISTRIBUTION) ||
                              (mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_VALIDATION) ||
                              (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_DEPOT)) &&
                            <LoadingButton
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  mouvement.status === StatusMouvement.ATTENTE_PAIEMENT ? "Etes-vous sûr de vouloir annuler la commande ?" : "Vous n'avez pas payé ?",
                                  "danger",
                                  mouvement.status === StatusMouvement.ATTENTE_PAIEMENT ? "Annuler la commande" : "Pas payé",
                                  "Annuler",
                                  null,
                                  mouvement.status === StatusMouvement.ATTENTE_PAIEMENT ? () => handleDeleteMouvement(mouvement.uid) : () => onNotPaiementMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              {mouvement.status === StatusMouvement.ATTENTE_PAIEMENT ? "Annuler" : "Pas payé ?"}
                            </LoadingButton>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                )}
              </Accordion>

              <h3
                className="section"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px"
                }}
              >
                Historique
              </h3>
              <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                {mouvementsAchat.length === 0 ? (
                  <Container><span style={{ fontStyle: "italic" }}>Aucune commande</span></Container>
                ) : (
                  mouvementsAchat.map((mouvement, index) => {
                    let panier = null;
                    if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                      panier = mouvement.panier;
                    }
                    const nbArticlesPanier = panier ? panier.reduce((accumulateur, t) => accumulateur + t["nb"], 0) : 0;

                    //const createur = await getLicencie(mouvement.createur);
                    //const remiseMembre = await getLicencie(mouvement.remiseMembreBureau);
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "10px"
                            }}
                          >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <span style={{ flex: "1 1 0", fontWeight: "bold" }}>{mouvement.motif}</span>
                              {panier && (
                                <span style={{ fontStyle: "italic" }}>{nbArticlesPanier} article{nbArticlesPanier > 1 && "s"}</span>
                              )
                              }
                            </div>
                            {mouvement.status !== StatusMouvement.CLOTURE && <span>{mouvement.status}</span>}
                            <span>{mouvement.montant}€</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <span style={{
                                        textWrap: "nowrap",
                                        textOverflow: "ellipsis",
                                        position: "relative",
                                        flex: "1 1 auto",
                                        overflow: "hidden"
                                      }}>
                                        {article.title}
                                      </span>
                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                              </div>
                            </>
                          )}
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{mouvement.createur === user.uid ? "Vous" : mouvement.createur.displayName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Date
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && mouvement.remiseMembreBureau.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            distribuePar={mouvement.distribuePar}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />

                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                )}
              </Accordion>
            </Container>

          </Tab>
        }
        {db.isMemberClub &&
          <Tab eventKey="porteFeuille"
            title=<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <FontAwesomeIcon icon={faWallet} style={{ fontSize: "1em", marginRight: "5px" }} />
              <span style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
                maxWidth: badgeDepotCompt > 0 ? "50px" : "",
                marginRight: "5px"
              }}>Portefeuille</span>
              {badgeDepotCompt > 0 && <Badge bg="warning" style={{ color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeDepotCompt}</Badge>}
            </div>
          >
            <Container>
              <div style={{ marginBottom: "5px", display: "flex", alignItems: "center" }}>
                <h1 style={{ marginBottom: "0px", textAlign: "center", width: "100%" }}>{porteFeuille !== null ? porteFeuille.toFixed(2) : "0.00"} €</h1>
                <LoadingButton className="btn btn-custom2" onClick={handleAddCreditPorteFeuille}>
                  Créditer
                </LoadingButton>
              </div>
              <h3
                className="section"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              > Dépôt en cours </h3>
              <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                {mouvementsDepotEncours.length === 0 ? (
                  <Container><span style={{ fontStyle: "italic" }}>Aucune opération</span></Container>
                ) : (
                  mouvementsDepotEncours.map((mouvement, index) => {
                    //const createur = await getLicencie(mouvement.createur);
                    //const remiseMembre = await getLicencie(mouvement.remiseMembreBureau);
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "10px"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <b>
                                <span style={{ flex: "1 1 0" }}>{mouvement.motif}</span>
                              </b>
                              <span style={{ fontSize: "0.8em" }}>{getDateShort(mouvement.date)}</span>
                            </div>
                            {mouvement.status !== StatusMouvement.CLOTURE && <span>{mouvement.status}</span>}

                            <span style={{ fontWeight: "bold" }}>{(mouvement.montant > 0 ? "+ " : "") + mouvement.montant}€</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>

                          <label className="labelKeyValue" >
                            Créé par
                            <span>{mouvement.createur === user.uid ? "Vous" : mouvement.createur.displayName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Date
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && mouvement.remiseMembreBureau.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            ((mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_VALIDATION) ||
                              (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_DEPOT)) &&
                            <LoadingButton
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  "Etes vous sur de vouloir retirer ce dépôt ?",
                                  "danger",
                                  "Retirer ce dépôt",
                                  "Annuler",
                                  null,
                                  () => handleDeleteMouvement(mouvement.uid)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              Annuler
                            </LoadingButton>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                )}
              </Accordion>

              <h3
                className="section"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px"
                }}
              >
                Historique
              </h3>
              <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                {mouvementsDepot.length === 0 ? (
                  <Container><span style={{ fontStyle: "italic" }}>Aucun mouvement</span></Container>
                ) : (
                  mouvementsDepot.map((mouvement, index) => {
                    let panier = null;
                    if (mouvement.achatUid) {
                      panier = mouvement.panier;
                    }
                    const nbArticlesPanier = panier ? panier.reduce((accumulateur, t) => accumulateur + t["nb"], 0) : 0;

                    //const createur = await getLicencie(mouvement.createur);
                    //const remiseMembre = await getLicencie(mouvement.remiseMembreBureau);
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginRight: "10px"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >

                              <b>
                                <span style={{ flex: "1 1 0" }}>{mouvement.motif}</span>
                              </b>
                              <span style={{ fontSize: "0.8em" }}>{getDateShort(mouvement.date)}</span>
                              {panier && (
                                <span style={{ fontStyle: "italic" }}>{nbArticlesPanier} article{nbArticlesPanier > 1 && "s"}</span>
                              )
                              }
                            </div>
                            {mouvement.status !== StatusMouvement.CLOTURE && <span>{mouvement.status}</span>}

                            <span>{(mouvement.montant > 0 ? "+ " : "") + mouvement.montant}€</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <span style={{
                                        textWrap: "nowrap",
                                        textOverflow: "ellipsis",
                                        position: "relative",
                                        flex: "1 1 auto",
                                        overflow: "hidden"
                                      }}>
                                        {article.title}
                                      </span>
                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant * (-1)}€ </b>
                              </div>
                            </>
                          )}
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{mouvement.createur === user.uid ? "Vous" : mouvement.createur.displayName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Date
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && mouvement.remiseMembreBureau.displayName}</span>
                            </label>
                          }
                          {mouvement.montant > 0 &&
                            <StatusTransaction
                              typeMouvement={mouvement.typeMouvement}
                              typeReglement={mouvement.typeReglement}
                              status={mouvement.status}
                              remiseMembreBureau={mouvement.remiseMembreBureau}
                              dansCoffre={mouvement.dansCoffre}
                              alreadyDistrib={mouvement.alreadyDistrib}
                            />
                          }
                          {
                            ((mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_VALIDATION) ||
                              (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.status === StatusMouvement.ATTENTE_DEPOT)) &&
                            <LoadingButton
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  "Etes vous sur de vouloir retirer ce dépôt ?",
                                  "danger",
                                  "Retirer ce dépôt",
                                  "Annuler",
                                  null,
                                  () => handleDeleteMouvement(mouvement.uid)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              Annuler
                            </LoadingButton>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                )}
              </Accordion>
            </Container>
          </Tab >
        }
      </Tabs >
      {
        db.isMemberClub &&
        <ModalMouvement
          show={modalMouvementShow}
          typeMouvement={currentTypeMouvement}
          currentUser={user}
          onHide={() => setModalMouvementShow(false)}
          onValid={onValid}
          onCancel={onCancel}
          userTarget={user}
        />
      }

    </>
  );
}

export default EditProfil;
